define("fimab-konfigurator/helpers/style-position", ["exports", "fimab-2d-view/helpers/style-position"], function (_exports, _stylePosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stylePosition.default;
    }
  });
  Object.defineProperty(_exports, "stylePosition", {
    enumerable: true,
    get: function get() {
      return _stylePosition.stylePosition;
    }
  });
});
