import Controller from "@ember/controller";
import { inject as service } from "@ember-decorators/service";
import moment from "moment";
import { action } from "@ember-decorators/object";
const localStoragePrefix = "fimab";
// Default-Konfiguration fuer Development

export default class Konfigurator extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service("order") orderService: any;
  @service() calculation: any;
  @service("graphql") apolloService: any;

  deliverydate?: string;
  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;

  agb = false;
  widerruf = false;

  isOrdering = false;
  successMessage = false;
  savingAddress = false;
  changePaymentAddress = false;
  changeShippingAddress = false;
  userHasOrdered = false;
  @action
  async saveAddress() {
    this.set("savingAddress", true);
    await this.orderService.saveOrder.perform(this.model.order);
    this.set("savingAddress", false);
    this.set("changePaymentAddress", false);
    this.set("changeShippingAddress", false);
  }

  @action
  async createOrder() {
    this.set("isOrdering", true);
    await this.orderService.createOrder(this.get("model.order"));
    this.set("isOrdering", false);
    this.set("userHasOrdered", true);
    this.set("successMessage", true);
  }

  constructor() {
    super(...arguments);
    this.weekday = parseInt(moment().format("d"));
    this.hour = parseInt(moment().format("k"));
    if (this.hour >= 16) {
      if (this.weekday === 6) {
        this.weekday = 0;
      } else {
        this.weekday++;
      }
      this.morgen = " morgen ";
      this.dayplus = 1;
    }

    if (this.weekday === 0) {
      this.daystoadd = 11 + this.dayplus;
    } else if (this.weekday === 6) {
      this.daystoadd = 12 + this.dayplus;
    } else {
      this.daystoadd = 13 + this.dayplus;
    }

    let feiertage = [
      "15.04.2022",
      "18.04.2022",
      "01.05.2022",
      "26.05.2022",
      "06.06.2022",
      "16.06.2022",
      "25.07.2022",
      "26.07.2022",
      "27.07.2022",
      "28.07.2022",
      "29.07.2022",
      "30.07.2022",
      "31.07.2022",
      "01.08.2022",
      "02.08.2022",
      "03.08.2022",
      "04.08.2022",
      "05.08.2022",
      "06.08.2022",
      "08.08.2022",
      "15.08.2022",
      "20.09.2022",
      "03.10.2022",
      "31.10.2022",
      "01.11.2022",
      "16.11.2022",
      "25.12.2022",
      "26.12.2022",
    ];
    let delay: number = 0;

    for (let u = 1; u <= this.daystoadd; u++) {
      console.log("u:" + u);
      let mom = moment().add(u, "days").format("DD.MM.Y");
      for (let s = 0; s <= feiertage.length; s++) {
        if (feiertage[s] == mom) {
          delay++;
          this.daystoadd++;
        }
      }
    }
    if (
      parseInt(
        moment()
          .add(this.daystoadd + delay, "days")
          .format("d")
      ) === 5
    ) {
      delay = delay + 3;
    }
    if (
      parseInt(
        moment()
          .add(this.daystoadd + delay, "days")
          .format("d")
      ) === 6
    ) {
      delay = delay + 2;
    }
    if (
      parseInt(
        moment()
          .add(this.daystoadd + delay, "days")
          .format("d")
      ) === 0
    ) {
      delay = delay + 1;
    }
    this.deliverydate = moment()
      .add(this.daystoadd + delay, "days")
      .format("DD.MM.Y");
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    konfigurator: Konfigurator;
  }
}
