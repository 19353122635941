import Route from '@ember/routing/route';
import { inject as service } from '@ember-decorators/service';
import RouterService from '@ember/routing/router-service';
import { getOwner } from '@ember/application';


export default class AccountLogout extends Route.extend({
    // anything which *must* be merged to prototype here
  }) {

    @service amplify: any;
    @service router?: RouterService;


    async beforeModel() {
      await this.amplify.Auth.signOut();
      let route = getOwner(this).lookup(`route:application`);
      await this.router.transitionTo('index');
  
      await route.refresh();
      return;
    }
}
