import Component from '@ember/component';
import {
  computed
} from '@ember-decorators/object';
import { inject as service } from '@ember-decorators/service';
import {
  isNone
} from '@ember/utils';
import {
  tagName,
  layout
} from '@ember-decorators/component';
import hbs from 'htmlbars-inline-precompile';


@tagName('')
@layout(hbs`
{{#if timestamp}}
{{#async-await this.fileUrl as |url|}}<img class="{{class}}" src={{url}} />{{/async-await}}
{{else}}
{{#async-await this.fileUrl as |url|}}<img class="{{class}}" src={{url}} />{{/async-await}}
{{/if}}
`)
export default class AwsFile extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  

  @service amplify: any;


  level: string = 'private';

  @computed("file")
  get fileUrl() {

    if (isNone(this.file)) {
      return '';
    }
    return this.amplify.Storage.get(this.file, {
      level: this.level
    }).then((fileUrl: string) => {
      return fileUrl;
    });
  }
};
