import File from 'fimab-konfigurator/model/file'

export default class Cabinet {

  id: string;
  name: string;
  category: string;
  width?: object;
  height?: object;
  laenge?: object;
  model?: File;
  sperrflaechen?: object;
  constructor(data: any) {
    const {
      id,
      name,
      category,
      model,
      width,
      height,
      laenge,
      sperrflaechen
    } = data;

    this.id = id;
    this.name = name;
    this.sperrflaechen = sperrflaechen;
    this.width = width;
    this.height = height;
    this.laenge = laenge;
    this.category = category;
    this.model = model ? new File(model): undefined;
  }
}
