import Controller from '@ember/controller';
import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember-decorators/service';
export default class AccountConfirm extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {

  @service amplify: any;
  @service router: any;
  @service('graphql') apolloService: any;

  queryParams: string[] = ['code', 'username', 'email', 'clientId'];
  code?: string =  null;
  username?: string =  null;
  email?: string =  null;
  clientId?: string =  null;
  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
  async onConfirm() {
    try {
      const email = this.changeset.get('email');
      const emailNotification = await fetch('https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: "bucher@netzreich.de",
          subject: `Neuer Benutzer: ${email}`,
          content: `Neuer Benutzer ${email}<br>
          ` 
        })
      });
      const content2 = await emailNotification.text();
    } catch (e) {
      console.log(e)
    }

   
  }

  @observes('code')
  confirmUser() {
    this.amplify.Auth.confirmSignUp(this.username, this.code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true    
    }).then(data => {

      //this.onConfirm();
      this.router.transitionTo('account.login', { queryParams: { success: 'Account erfolgreich aktiviert!' }});
    })
      .catch(err => {
        this.router.transitionTo('account.login', { queryParams: { success: 'Account erfolgreich aktiviert!' }});
      });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'account/confirm': AccountConfirm;
  }
}
