import Route from '@ember/routing/route';

export default class AccountLogin extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  // normal class body definition here
  beforeModel() {
    if (this.modelFor('application') !== null) {

    }
  }
}
