
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('fimab-konfigurator/app')['default'].create({"name":"fimab-konfigurator","version":"1.67.33+f52ff2f8"});
  }
}

define('~fastboot/app-factory', ['fimab-konfigurator/app', 'fimab-konfigurator/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

