import Service from "@ember/service";
import { set } from "@ember/object";
import { computed } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import uuid from "uuid/v4";
import gql from "graphql-tag";
import Order from "fimab-konfigurator/model/order";
import CREATE_ORDER from "fimab-konfigurator/gql/order/createOrder";
import { isEmpty } from "@ember/utils";
import query from "fimab-konfigurator/gql/order/findBy";
import createOrder from "fimab-konfigurator/gql/order/createOrder";
import updateOrder from "fimab-konfigurator/gql/order/updateOrder";
import { getOwner } from "@ember/application";
import moment from "moment";
import {
  task,
  dropTask,
  enqueueTask,
  restartableTask,
} from "ember-concurrency-decorators";
import { all, timeout } from "ember-concurrency";
import fetch from "fetch";
import { base64StringToBlob } from "blob-util";
import Evented from "@ember/object/evented";

const LATEST_ORDERNUMBER = gql`
  query {
    orders(orderBy: ordernumber_DESC, first: 1) {
      id
      ordernumber
    }
  }
`;

export default class OrderService extends Service.extend(Evented) {
  @service("graphql") apolloService: any;
  @service() amplify: any;
  @service() calculation: any;

  order: any;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());

    /*setInterval(()=>{
      if (this.get('order').share !== null) {
        this.saveOrder.perform(this.get('order'));
      }

    }, 10000);*/
  }
  isSaveable() {
    if (isEmpty(this.get("amplify.currentUser.id"))) {
      return false;
    }
    if (isEmpty(this.get("order.name"))) {
      return false;
    }
    return true;
  }

  daystoadd: number;
  weekday: number;
  hour: number;
  morgen: string;
  dayplus: number;

  deliveryDate() {
    // Lieferdatum errechnen ----------------
    this.weekday = parseInt(moment().format("d"));
    this.hour = parseInt(moment().format("k"));
    if (this.hour >= 16) {
      if (this.weekday === 6) {
        this.weekday = 0;
      } else {
        this.weekday++;
      }
      this.morgen = " morgen ";
      this.dayplus = 1;
    }

    if (this.weekday === 0) {
      this.daystoadd = 11 + this.dayplus; // +7 Tage weil 3 Wochenende!
    } else if (this.weekday === 6) {
      this.daystoadd = 12 + this.dayplus;
    } else {
      this.daystoadd = 13 + this.dayplus;
    }

    let feiertage = [
      "15.04.2022",
      "18.04.2022",
      "01.05.2022",
      "26.05.2022",
      "06.06.2022",
      "16.06.2022",
      "25.07.2022",
      "26.07.2022",
      "27.07.2022",
      "28.07.2022",
      "29.07.2022",
      "30.07.2022",
      "31.07.2022",
      "01.08.2022",
      "02.08.2022",
      "03.08.2022",
      "04.08.2022",
      "05.08.2022",
      "06.08.2022",
      "08.08.2022",
      "15.08.2022",
      "20.09.2022",
      "03.10.2022",
      "31.10.2022",
      "01.11.2022",
      "16.11.2022",
      "25.12.2022",
      "26.12.2022",
    ];
    let delay: number = 0;

    let mom = moment();
    let u = 0;

    while (u <= this.daystoadd) {
      mom.add(1, "days");

      if (feiertage.indexOf(mom.format("DD.MM.Y")) < 1) {
        u++;
      }
    }

    if (parseInt(mom.format("d")) === 5) {
      mom.add(3, "days");
    }

    return mom.format("DD.MM.Y");
  }

  @restartableTask({
    maxConcurrency: 1,
  })
  *reloadOrder(order: any) {
    yield this.refreshCache.perform(order);
    this.set("order", order);
  }

  async getLatestOrderByShare(params) {
    let order;
    console.log("getLatestOrderByShare", params);
    if (params.hasOwnProperty("konfigurator_share")) {
      let cachedOrder = this.amplify.Cache.getItem(
        `fimab.${params.konfigurator_share}`
      );
      console.log("cachedOrder", cachedOrder);
      if (cachedOrder !== null) {
        let order = new Order(JSON.parse(cachedOrder));
        this.set("order", order);
        order = await this.saveOrder.perform(order);
      }
      const variables = {
        where: {
          share: params.konfigurator_share,
        },
      };
      order = await this.apolloService.query(
        { query, variables, fetchPolicy: "network-only" },
        "order"
      );
      console.log("order", order);
      this.set("order", order);
      if (order !== null) {
        return order;
      }
    }
    return false;
  }

  @restartableTask({
    maxConcurrency: 1,
  })
  *refreshCache(configuration) {
    this.amplify.Cache.setItem("currentOrder", configuration.share);
    this.amplify.Cache.setItem(
      `fimab.${configuration.share}`,
      JSON.stringify(configuration)
    );
  }

  @restartableTask({
    maxConcurrency: 1,
  })
  *cacheOrder(order) {
    if (order == null) {
      return false;
    }
    this.set("order", order);

    this.amplify.Cache.setItem(`fimab.${order.share}`, JSON.stringify(order));

    return order;
  }

  @task()
  *setOrderStatus(order: any, newStatus: string) {
    const variables = {
      data: {
        status: newStatus,
        ordernumber: order.ordernumber,
      },
      where: {
        id: order.id,
      },
    };

    return this.get("apollo").mutate({ mutation: updateOrder, variables });
  }

  @task()
  *uploadImage(screen, imageBase64, order, timestamp) {
    let key = `OrderScreens/${order.id}/${timestamp}/${screen}.jpg`;
    let imageData = yield fetch(`data:image/jpg;base64,${imageBase64}`);
    let blobData = yield imageData.blob();
    let s3Image = yield this.amplify.Storage.put(key, blobData, {
      level: "public",
    });
    this.set(`variables.data.s3screens.${screen}`, key);
  }

  @task()
  *createNewOrderFromExisting(existingOrder) {
    let order = new Order({
      share: uuid(),
      name: `${existingOrder.name} (Kopie)`,
      color: existingOrder.color,
      isPublic: false,
      material: existingOrder.material,
      finish: existingOrder.finish,
      status: "",
      count: existingOrder.count,
      cabinet: existingOrder.cabinet,
      moduleDefinition: {
        ...existingOrder.moduleDefinition,
        meta: {
          oldShare: existingOrder.share,
          oldId: existingOrder.id,
          oldUser: existingOrder.user != null ? existingOrder.user.id : null,
        },
      },
    });

    delete order.createdAt;
    const variables: any = {
      data: order,
    };
    if (!isEmpty(this.get("amplify.currentUser.id"))) {
      variables.data.user = {
        connect: {
          id: this.get("amplify.currentUser.id"),
        },
      };
    }
    try {
      let savedOrder = yield this.apollo.mutate(
        {
          mutation: CREATE_ORDER,
          variables,
        },
        "createOrder"
      );
      let order = new Order(savedOrder);
      yield this.cacheOrder.perform(order);
      this.amplify.Cache.removeItem("stepsDone");
      return order;
    } catch (e) {
      console.error("Error saving new Order", e);
    }
  }

  @restartableTask({
    maxConcurrency: 1,
  })
  *saveOrder(order) {
    console.log("saveorder");
    const configuration = order;

    if (configuration == null) {
      return;
    }
    delete configuration.account;
    delete configuration.__typename;

    delete configuration.createdAt;

    //if (!this.get('order.screens') == null) {
    //  this.set('order.s3screens', {});
    //  this.get('order.screens').forEach(element => {
    //    const storagePath = `${this.amplify.currentUser.company}/${this.order.share}/${element.Seite}.jpg`;
    //    let path = this.uploadImage.perform(element, storagePath);
    //    this.set(`order.s3screens.${element.Seite}`, storagePath);
    //  });
    //}
    let variables = {
      where: {
        id: this.get("order.id"),
      },
      data: {
        ...configuration,
      },
    };
    if (this.amplify.isAuthenticated && order.user == null) {
      variables.data.user = {
        connect: {
          id: this.get("amplify.currentUser.id"),
        },
      };
    } else {
      if (variables.data.user) {
        variables.data.user = {
          connect: {
            id: variables.data.user.id,
          },
        };
      }
    }

    delete variables.data.account;
    delete variables.data.createdAt;
    delete variables.data.id;

    let screenTasks: any = [];

    this.set("variables", variables);
    let timestamp = Date.now();
    if (variables.data.screens && this.amplify.isAuthenticated) {
      this.set("variables.data.s3screens", {});
      variables.data.screens.forEach((s) => {
        let screenTask = this.uploadImage.perform(
          s.id,
          s.imageBase64,
          order,
          timestamp
        );
        screenTasks.push(screenTask);
      });
      yield all(screenTasks);
    }
    console.log("images saved");
    variables = this.get("variables");
    delete variables.data.screens;

    /*const rawResponse = yield fetch('https://kt6ybrfiwg.execute-api.eu-central-1.amazonaws.com/prod/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.order)
    });
    const content = yield rawResponse.blob();*/

    variables.data.count = parseInt(variables.data.count);
    if (variables.data.status == "") {
      delete variables.data.status;
    }
    let resp = yield this.get("apollo").mutate(
      {
        mutation: updateOrder,
        variables,
        fetchPolicy: "no-cache",
        refetchQueries: ["findByShare"],
      },
      "updateOrder"
    );
    console.log("updateOrder");

    let newOrder = new Order(resp);

    yield this.reloadOrder.perform(newOrder);
    console.log("reloadOrder");
    yield this.trigger("order:saved");
    return;
  }

  async getNextOrderNumber() {
    let resp = await this.apolloService.query(
      {
        query: LATEST_ORDERNUMBER,
        fetchPolicy: "network-only",
      },
      "orders"
    );
    let next = resp.firstObject.ordernumber + 1;

    return next;
  }

  async createOrder(order: any) {
    if (isEmpty(order.ordernumber)) {
      let ordernumber = await this.getNextOrderNumber();
      set(order, "ordernumber", ordernumber);
    }
    set(order, "status", "ORDERED");
    await this.saveOrder.perform(order);
    await this.setOrderStatus.perform(order, "ORDERED");
    await this.exportXML(order);
    fbq("track", "Purchase");
  }

  async cleanup() {
    this.amplify.Cache.removeItem("currentOrder");
    this.amplify.Cache.removeItem("stepsDone");

    this.set("stepsDone", {
      step1: false,
      step2: false,
      step3: false,
    });
  }

  async exportXML(order: any) {
    const user = this.get("amplify.user");
    let bestellvermerk = order.moduleDefinition.bestellvermerk;
    if (bestellvermerk == null || bestellvermerk == undefined) {
      bestellvermerk = "";
    }
    set(
      order,
      "price",
      Math.round((this.get("amplify.preis") + Number.EPSILON) * 100) / 100
    );

    try {
      const emailNotification = await fetch(
        "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: "intern",
            subject: `Order: ${order.ordernumber} / Kundenbezeichnung: ${order.name}`,
            content: `Neue Bestellung von ${user.username}<br>
          ${order.cabinet.name} - B ${order.moduleDefinition.Breite} H ${
              order.moduleDefinition.Hoehe
            } T ${order.moduleDefinition.Laenge}
          <br>
          Summe: ${Math.round(order.price * 100) / 100}<br>
          <br>
          <p>
          Bestellvermerk: <br>
            ${bestellvermerk}
          </p>
          <br>
          Der Kunde hat folgende abweichende Daten angegeben:<br>
          <br>
          Lieferadresse:<br>
          Firma: ${order.moduleDefinition.alternateShipping?.company}<br>
          ZIP: ${order.moduleDefinition.alternateShipping?.zip}<br>
          Stadt: ${order.moduleDefinition.alternateShipping?.city}<br>
          Straße: ${order.moduleDefinition.alternateShipping?.address}<br>
          Ort: ${order.moduleDefinition.alternateShipping?.city}<br>
          <br>
          Rechnungsadresse:<br>
          Firma: ${order.moduleDefinition.alternatePayment?.company}<br>
          ZIP: ${order.moduleDefinition.alternatePayment?.zip}<br>
          Stadt: ${order.moduleDefinition.alternatePayment?.city}<br>
          Straße: ${order.moduleDefinition.alternatePayment?.address}<br>
          Ort: ${order.moduleDefinition.alternatePayment?.city}<br>
          `,
          }),
        }
      );

      // Mail an den Kunden
      const emailNotificationCustomer = await fetch(
        "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: order.user.email,
            cc: order.moduleDefinition.orderBuyer,
            subject: `Vielen Dank für Ihre Bestellung: ${order.ordernumber} / Interne Bezeichnung: ${order.name}`,
            content: `Sehr geehrte/r ${order.user.salutation} ${order.user.lastname}<br>
          Vielen Dank für Ihre Bestellung auf ARMARiO. <br><br>Diese wird von unserem Team noch kurz geprüft. Eine detailierte Auftragsbestätigung erhalten Sie am nächsten Arbeitstag und Sie dürfen sich schon jetzt auf ihren individuellen Schaltschrank freuen.
          <br>Technische Daten: <a href="https://www.fimab.eu/technische-daten" target="_blank">https://www.fimab.eu/technische-daten</a><br><br><br>
          Freundliche Grüße aus dem Nordschwarzwald,<br>
          Ihr ARMARiO Team
          <br><br><br><br>
          -------------------<br>
          ARMARiO ist eine Marke der FiMAB.<br>
          FiMAB GmbH & Co. KG<br>
          Bühlstraße 8<br>
          75387 Neubulach<br>
          Telefon: +49 7053 96839-0<br>
          Telefax: +49 7053 96839-19<br>
          E-Mail: info@fimab.eu<br>
          http://www.fimab.eu/<br>
          Geschäftsführer: Markus Fiedler<br>
          Registergericht Amtsgericht Stuttgart, HRB 330801
          `,
          }),
        }
      );

      const content2 = await emailNotification.text();
      const content3 = await emailNotificationCustomer.text();
    } catch (e) {
      console.log(e);
    }

    let logmodel = order;
    delete logmodel.screens;

    order.moduleDefinition.delivery = this.deliveryDate();
    try {
      this.get("amplify.Storage").put(
        `${order.get("user.email")}/O_${order.get(
          "ordernumber"
        )}_${new Date().getTime()}.json`,
        JSON.stringify(logmodel),
        { level: "public" }
      );
    } catch (e) {}
    try {
      const rawResponse = await fetch(
        "https://l24efwnud4.execute-api.eu-central-1.amazonaws.com/local/export",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order: order,
            user: order.user,
          }),
        }
      );
      const content = await rawResponse.text();
    } catch (e) {
      console.log(e);
    }
  }

  @computed(
    "order.moduleDefinition.Breite",
    "order.moduleDefinition.Hoehe",
    "order.moduleDefinition.Laenge",
    "order.moduleDefinition.ausschnitte",
    "order.material",
    "order.finish",
    "order.count",
    "order.color.name",
    "price"
  )
  get newPrice() {
    return this.get("calculation").getCalculatedPrice(this.get("order"));
  }

  @computed(
    "order.moduleDefinition.Breite",
    "order.moduleDefinition.Hoehe",
    "order.moduleDefinition.Laenge",
    "order.moduleDefinition.ausschnitte",
    "order.material",
    "order.finish",
    "order.count",
    "order.color.name"
  )
  get price() {
    if (!this.order) {
      return 0;
    }

    this.get("calculation").getCalculatedPrice(this.get("order"));

    return this.get("calculation").getCalculatedPrice(this.get("order")).cost;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module "@ember/service" {
  interface Registry {
    order: Order;
  }
}
