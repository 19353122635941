import Service from '@ember/service';
import Amplify, {
  Auth,
  I18n,
  Analytics,
  Storage,
  Cache,
  Logger
} from 'aws-amplify'

import awsmobile from '../aws-exports'
import { computed } from '@ember-decorators/object';
import { isNone } from '@ember/utils';
import Evented from '@ember/object/evented';


export default class AmplifyService extends Service.extend(Evented) {
  Amplify = Amplify;
  Auth = Auth;
  I18n = I18n;
  Analytics = Analytics;
  
  Storage = Storage;
  
  Cache = Cache;
  Logger = new Logger('Application');

  currentUser: CognitoUser = null;


  @computed('currentUser') 
  get isAuthenticated(): Boolean {
    return !isNone(this.currentUser);
  }

  constructor() {
    super(...arguments);
    window.LOG_LEVEL = 'ERROR';
    this.Amplify.configure(awsmobile);
    this.Storage.configure({ level: 'private' });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'amplify': AmplifyService;
  }
}
