import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
export default Mixin.create({

  apolloService: service('graphql'),
  amplify: service(),

  init() {
    this._super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
});
