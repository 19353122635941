import Controller from '@ember/controller';
import { action } from '@ember-decorators/object';
import { inject as service } from '@ember-decorators/service';


export default class Application extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {

  @service amplify: any;


  isSearchModalOpen: Boolean;
  isBrowserMessageOpen: Boolean = true;

  constructor() {
    super(...arguments);
    this.isSearchModalOpen = false;
  }

  @action
  closeBrowserPopup() {
    this.set('isBrowserMessageOpen', false);
  }

  @action
  openSearchModal() {
    this.set('isSearchModalOpen', true);
  }

}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'application': Application;
  }
}
