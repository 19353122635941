import Component from '@ember/component';
import { action } from '@ember-decorators/object';

export default class AppSearch extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  isOpen: Boolean;

  constructor() {
    super(...arguments);
    this.isOpen = false;
  }

  @action
  close() {
    this.set('isOpen', false)
  }

  @action
  open() {
    this.set('isOpen', true)
  }
};
