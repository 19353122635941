import Component from '@ember/component';
import { action } from '@ember-decorators/object';
import { isEmpty } from '@ember/utils';

export default class AccountMain extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  showLogin: boolean = true;
  open: boolean = true;

  error?: object|null = null;

  step: number = 1;

  challenge: string = 'LOGIN';

  @action
  afterRegister(user: object) {

    this.set('showLogin', true);
  }

  @action
  showLogin() {
    this.set('showLogin', true);
  }
  @action
  showRegister() {
    this.set('showLogin', false);
  }
  @action
  onLogin() {
    this.set('open', false);
  }

  @action
  onHide() {
    this.set('open', false);
  }

  @action 
  onError(error: object) {
    
    if (error.code == 'UsernameExistsException') {
      this.set('error', {
        message: 'Diese E-Mail-Adresse ist bereits vergeben'
      });
      this.set('step', 1);
    }
    if (error.code == 'UserNotConfirmedException') {
      this.set('error', {
        message: 'Dieser Account ist noch nicht aktiviert, wir haben Ihnen den Aktivierungscode erneut zugesandt.'
      });
      this.set('step', 3);
      this.send('showRegister');
    }
  }

  @action
  removeErrorMessage() {
    this.set('error', null);
  }
};
