import Route from '@ember/routing/route';
import { inject as service } from '@ember-decorators/service';
import query from 'fimab-konfigurator/gql/order/findMany';
import { isEmpty } from '@ember/utils';

export default class Konfiguratoroverview extends Route.extend({
  // anything which *must* be merged to prototype here
}) {

  @service() amplify;
  @service('graphql') apolloService: any;

  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }

  async model() {
    let userId = this.get('amplify.currentUser.id');
    if (isEmpty(userId)) {
      userId = '';
    } 
    let variables = { 
      where: {
        user: {
          id: userId
        },
        deleted_not: true
      }
    };
    let orders = await this.get('apollo').query( {query, variables}, 'orders');
 
    return orders.reverse().filterBy('deleted', false);
  }
}

