import Route from "@ember/routing/route";
import { inject as service } from "@ember-decorators/service";
import query from "fimab-konfigurator/gql/user/user";
import findAccount from "fimab-konfigurator/gql/account/findBy";
import User from "fimab-konfigurator/model/user";
import * as Sentry from "@sentry/browser";

export default class Application extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service amplify: any;
  @service("graphql") apolloService: any;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  model() {
    return this.amplify.Auth.currentAuthenticatedUser()
      .then(async (user: CognitoUser) => {
        let userinfo = await this.amplify.Auth.currentUserInfo();

        const variables = {
          where: {
            cognitoUser: userinfo.attributes.sub,
          },
        };
        const userData = await this.get("apollo").query(
          {
            query,
            variables,
          },
          "user"
        );
        const userModel = new User(userData);
        userModel.cognitoUser = user;
        this.amplify.set("currentUser", userModel);
        Sentry.configureScope((scope) => {
          scope.setUser({ id: userModel.customerId });
        });

        const model = {
          user: userModel,
        };
        this.amplify.set("user", user);
        return model;
      })
      .catch((err) => {
        console.error(err);
        this.amplify.set("currentUser", null);
        return {};
      });
  }
}
