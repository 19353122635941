import Component from '@ember/component';
import { action } from '@ember-decorators/object';
import { inject as service } from '@ember-decorators/service';
import { alias } from '@ember-decorators/object/computed';
export default class AccountLoginChallengeSetnewpassword extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('router')
  router;
  @alias('router._router.currentState.routerJsState.fullQueryParams')
  params;

  @action
  newPassword() {
    if (this.password === this.password2) {
      this.onNewPassword(this.params.username.toLowerCase(), this.params.code, this.password);
    }
  }
};
