import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action } from '@ember-decorators/object';

@tagName('')
export default class AppWizard extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  // normal class body definition here
  @action
  linkToStep4() {
    console.log(1);
    
  }
}
