import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function() {
  this.route('account', function() {
    this.route('register');
    this.route('login');
    this.route('logout');
    this.route('profile', function() {
      this.route('overview');
    });
    this.route('overview');
    this.route('settings');
    this.route('confirm');
    this.route('newpassword');
  });
  this.route('konfiguratoroverview');
  this.route('konfiguratorinit');
  this.route('konfigurator', { path: '/konfigurator'});
  this.route('konfigurator', { path: '/konfigurator/:konfigurator_share'});
  this.route('orderconfirmation', { path: '/orderconfirmation/:konfigurator_share'});
  this.route('privacy');
  this.route('faq');
  this.route('konfiguratorerror');

});

export default Router;
