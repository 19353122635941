import Component from "@ember/component";
import { tagName } from "@ember-decorators/component";
import query from "fimab-konfigurator/gql/component/findAll";
import { inject as service } from "@ember-decorators/service";
import { computed, action } from "@ember-decorators/object";
import { isEmpty } from "@ember/utils";
import { set } from "@ember/object";
import uuid from "uuid/v4";
import Ember from "ember";
import { run } from "@ember/runloop";

@tagName("")
export default class AppComponents extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService: any;
  @service() amplify: any;

  activeFilter: string = "";
  searchString: string = "";

  width: number = 1000;
  height: number = 1000;

  showComponentsView: boolean = true;

  makrobreite: number = 100;
  makrohoehe: number = 100;
  makrorundung: number = 0;
  makrodurchmesser: number = 30;

  makrobreitepreview: number = 100;
  makrohoehepreview: number = 100;
  makrorundungpreview: number = 0;
  makrodurchmesserpreview: number = 150;
  makrodurchmesserroundingpreview: number = 75;

  makrolanglochbreite: number = 50;
  makrolanglochdurchmesser: number = 50;

  recomputeBlocks: boolean = false;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
    this.amplify.on("makro:dropped", this, "toggleRecompute");
    this.amplify.on("3DView:activeSide", this, "activeSide");
  }
  willDestroyElement() {
    this.amplify.off("makro:dropped", this, "toggleRecompute");
    this.amplify.off("3DView:activeSide", this, "activeSide");
  }

  /**
   *
   * @param size
   */
  activeSide(size: object) {
    this.set("width", size.Seitenbreite);
    this.set("height", size.Seitenhoehe);
  }

  parseIntInputRounded(input: string) {
    if (isNaN(input)) {
      return 0;
    }
    if (typeof input !== "string") {
      input = input.toString();
    }
    return Math.round(parseInt(input.replace(",", ".")));
  }
  parseIntInput(input: string) {
    if (isNaN(input)) {
      // return 0;
    }
    if (typeof input !== "string") {
      input = input.toString();
    }
    input = input.replace(",", ".");
    if (input.indexOf(".") > 0) {
      let _temp = input.split(".");
      let dec = _temp[1].substring(0, 2);
      input = _temp[0] + "." + dec;
    }

    // return Math.round((input.replace(',', '.') * 100 ) /100);
    return input;
  }
  @action
  onFocusOut() {
    this.set("makrodurchmesser", this.parseIntInput(this.makrodurchmesser));
    this.set("makrodurchmesserpreview", 150);
    this.set("makrodurchmesserroundingpreview", 75);
  }
  @computed("recomputeBlocks", "makrobreite", "makrohoehe", "makrorundung")
  get block() {
    this.set("makrobreite", this.parseIntInput(this.makrobreite));
    this.set("makrohoehe", this.parseIntInput(this.makrohoehe));
    this.set("makrorundung", this.parseIntInput(this.makrorundung));

    // Makro Rechteck skalieren #259
    if (this.makrobreite > this.makrohoehe) {
      let makrobreiteskaler = this.makrobreite;
      this.set("makrobreitepreview", 150);

      makrobreiteskaler = this.makrobreitepreview / this.makrobreite;
      this.set("makrohoehepreview", this.makrohoehe * makrobreiteskaler);
      this.set("makrorundungpreview", this.makrorundung * makrobreiteskaler);
    } else {
      let makrohoeheskaler = this.makrohoehe;
      this.set("makrohoehepreview", 150);

      makrohoeheskaler = this.makrohoehepreview / this.makrohoehe;
      this.set("makrobreitepreview", this.makrobreite * makrohoeheskaler);
      this.set("makrorundungpreview", this.makrorundung * makrohoeheskaler);
    }

    this.set("makrobreitepreview", this.parseIntInput(this.makrobreitepreview));
    this.set("makrohoehepreview", this.parseIntInput(this.makrohoehepreview));
    this.set(
      "makrorundungpreview",
      this.parseIntInput(this.makrorundungpreview)
    );
    return {
      id: 240475,
      fimabId: 240475,
      name: `Rechteck: B ${this.makrobreite} H ${this.makrohoehe} R ${this.makrorundung}`,
      w: this.parseIntInput(this.makrobreite),
      h: this.parseIntInput(this.makrohoehe),
      macro: true,
      minW: 3,
      maxW: 1000,
      minH: 3,
      maxH: 1000,
      margin: this.COMPONENT_MARGIN,
      offset: {
        x: 0,
        y: 0,
      },
      rounding: this.parseIntInput(this.makrorundung),
    };
  }

  @computed("recomputeBlocks", "makrodurchmesser")
  get block2() {
    return {
      id: 240473,
      fimabId: 240473,
      name: `Rundloch: D ${this.makrodurchmesser}`,
      w: this.parseIntInput(this.makrodurchmesser),
      h: this.parseIntInput(this.makrodurchmesser),
      macro: true,
      minW: 3,
      maxW: 1000,
      minH: 3,
      maxH: 1000,
      margin: this.COMPONENT_MARGIN,
      offset: {
        x: 0,
        y: 0,
      },
      rounding: this.parseIntInput(this.makrodurchmesser) / 2,
    };
  }

  @computed(
    "recomputeBlocks",
    "makrolanglochbreite",
    "makrolanglochdurchmesser"
  )
  get block3() {
    return {
      id: uuid(),
      name: `Langloch`,
      h: this.makrolanglochdurchmesser,
      w: this.makrolanglochbreite,
      macro: true,
      rounding: this.makrolanglochdurchmesser / 2,
    };
  }

  toggleRecompute() {
    this.toggleProperty("recomputeBlocks");
  }

  @action
  showComponents() {
    this.set("showComponentsView", true);
  }
  @action
  showMakros() {
    this.set("showComponentsView", false);
  }
  @action
  onMouseDown(event) {
    event.preventDefault();
    return false;
  }

  @computed("components", "activeFilter", "searchString")
  get filteredComponents() {
    return this.get("components").then((components) => {
      if (isEmpty(this.activeFilter) && isEmpty(this.searchString)) {
        return this.get("components");
      }
      if (!isEmpty(this.activeFilter)) {
        components = components.filterBy("category", this.activeFilter);
      }
      if (!isEmpty(this.searchString)) {
        components = components.filter((component) => {
          let componentName = component.name.toLowerCase();
          return componentName.includes(this.searchString.toLowerCase());
        });
      }
      return components;
    });
  }

  COMPONENT_MARGIN = 5;

  @computed()
  get components() {
    const components = this.apollo
      .query({ query }, "components")
      .then((items) => {
        return items.map((component) => {
          component.open = false;
          return {
            id: component.fimabId,
            fimabId: component.fimabId,
            name: component.name,
            category: component.category,
            w:
              component.outerWidth > 0
                ? component.outerWidth + this.COMPONENT_MARGIN * 2
                : component.innerWidth + this.COMPONENT_MARGIN * 2,
            h:
              component.outerHeight > 0
                ? component.outerHeight + this.COMPONENT_MARGIN * 2
                : component.innerHeight + this.COMPONENT_MARGIN * 2,
            margin: this.COMPONENT_MARGIN,
            offset: {
              x: 0,
              y: 0,
            },
          };
          return {
            id: "macro1",
            name: "Macro I",
            w: 100,
            h: 100,
            resizable: true,
            minW: 20,
            maxW: 2000,
            minH: 20,
            maxH: 4000,
            offset: {
              x: 0,
              y: 0,
            },
          };
        });
      });
    return components;
  }
  @computed()
  get options() {
    let options = [];
    return this.get("components").then((components) => {
      components.forEach((element: object) => {
        if (!options.includes(element.category)) {
          options.pushObject(element.category);
        }
      });
      options.unshiftObject("Alle");
      return options;
    });
  }

  @action
  searchByString() {}

  dropTargetSelector = ".fimab-board__cabinetSurface";
  dragObject: ComponentDefinition | undefined;
  cabinetSurfaceRect: CabinetScreenRect | undefined;
  dragPreviewElement: JQuery<Element> | undefined;

  @action handleComponentItemMouseDown(
    component: ComponentDefinition,
    event: MouseEvent
  ) {
    set(
      this,
      "dragPreviewElement",
      this.createDragPreviewElement(
        component,
        event.pageX,
        event.pageY - component.height
      )
    );
    FIMAB_CONFIGURATOR.dragObject = component;
    document.addEventListener(
      "mousemove",
      this.handleComponentItemMouseMove,
      true
    );
    document.addEventListener(
      "mouseup",
      this.handleComponentItemMouseUp,
      false
    );
  }

  handleComponentItemMouseUp = (/* event: MouseEvent */) => {
    if (!FIMAB_CONFIGURATOR.dragObject) {
      return;
    }
    FIMAB_CONFIGURATOR.dragObject = undefined;
    this.dragPreviewElement!.remove();
    document.removeEventListener(
      "mousemove",
      this.handleComponentItemMouseMove,
      true
    );
    document.removeEventListener(
      "mouseup",
      this.handleComponentItemMouseUp,
      false
    );
    window.isDragging = false;
  };

  handleComponentItemMouseMove = (event: MouseEvent) => {
    if (!FIMAB_CONFIGURATOR.dragObject) {
      return;
    }
    const x = event.pageX;
    const y = event.pageY;
    this.dragPreviewElement!.css({ top: y, left: x });

    window.isDragging = true;
    if (
      event.target.classList.contains(
        "fimab-board__componentInstanceContentInner"
      )
    ) {
      event.stopPropagation();
    } else {
    }
  };

  @action handleChangeCabinetMeta(data: any) {
    set(this, "cabinetSurfaceRect", data);
  }

  createDragPreviewElement(
    component: ComponentDefinition,
    x: number,
    y: number
  ) {
    const w = component.w * window.FIMAB_CONFIGURATOR.cabinetSurfaceRect!.ratio;
    const h = component.h * window.FIMAB_CONFIGURATOR.cabinetSurfaceRect!.ratio;
    return Ember.$(
      `<div><img src="/components/${component.fimabId}_FRO.PNG"></div`
    )
      .addClass("componentDragPreview")
      .css({ width: w, height: h, top: y, left: x })
      .appendTo("body");
  }

  @action
  openModal(singleComponent: any) {
    const origin = window.location.origin;
    window.open(
      `${origin}/components/${singleComponent.fimabId}.PDF`,
      `${singleComponent.name}`,
      "height=800,width=1200,menubar=no,status=no,titlebar=no,toolbar=no"
    );
  }

  @action
  closeModal(singleComponent: any) {
    set(singleComponent, "open", false);
  }
}
