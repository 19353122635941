import Route from "@ember/routing/route";
import { inject as service } from "@ember-decorators/service";
import CREATE_ORDER from "fimab-konfigurator/gql/order/createOrder";
import uuid from "uuid/v4";
import Order from "fimab-konfigurator/model/order";
import { isEmpty } from "@ember/utils";

export default class Konfigurator extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify;
  @service("order") orderService;
  @service("graphql") apolloService: any;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  async model(params) {
    fbq("track", "CustomizeProduct");

    let resp = await this.orderService.getLatestOrderByShare(params);

    if (resp == false) {
      return this.transitionTo("konfiguratorinit");
    } else {
      return new Order(resp);
    }
  }
}
