import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import { Router } from "@ember/routing";
import { getOwner } from "@ember/application";

export default class AccountLogin extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  password: String = "";
  email: String = "";
  forgotPasswordEmail: String = "";

  @service amplify: any;
  @service router?: Router;

  isLoading: any;
  showPasswordReset: boolean = false;
  showForgotPassword: boolean = false;

  error?: object | null = null;
  success?: object | null = null;

  user?: object | null = null;
  newPasswordRequired: boolean = false;

  CHALLENGES: string[] = [
    "LOGIN",
    "NEWPASSWORD",
    "NEWPASSWORDSEND",
    "FORGOTPASSWORD",
  ];

  challenge: string = "LOGIN";

  @action
  async signIn() {
    this.set("isLoading", true);

    try {
      let user = await this.amplify.Auth.signIn(this.email, this.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.handleNewPasswordRequired(user);
      } else {
        await this.afterLoginSuccess();
        this.amplify.trigger("account:login", user);
        this.onLogin();
      }
    } catch (err) {
      console.log(err);
      this.set("isLoading", false);
      if (err.code === "UserNotConfirmedException") {
        this.handleUserNotConfirmedException();
      } else if (err.code === "PasswordResetRequiredException") {
        this.handlePasswordResetRequiredException();
      } else if (err.code === "NotAuthorizedException") {
        this.handleNotAuthorizedException();
      } else if (err.code === "UserNotFoundException") {
        this.handleUserNotFoundException();
      } else {
        console.error(err);
      }
    }
  }

  async handleNewPasswordRequired(user) {
    this.set("user", user);
    this.set("challenge", "NEWPASSWORD");
  }

  @action
  async handleNewPassword(newPassword: string) {
    this.amplify.Auth.completeNewPassword;
    const loggedUser = await this.amplify.Auth.completeNewPassword(
      this.user, // the Cognito User Object
      newPassword, // the new password
      {
        email: this.email,
      }
    );
    this.set("challenge", "LOGIN");
    this.set("success", {
      message: "Ihr Passwort wurde neu gesetzt. Sie können sich nun einloggen.",
    });
  }

  handleUserNotFoundException() {
    this.set("error", {
      message:
        "Dieser Account ist bei ARMARIO nicht hinterlegt. Bitte registrieren Sie sich zuerst.",
    });
  }

  handleNotAuthorizedException() {
    this.set("error", {
      message:
        "Das Passwort und/oder die E-Mail Adresse sind falsch oder Sie haben Ihr Passwort zu oft falsch eingegeben. Bitte versuchen Sie es in 5 Minuten erneut.",
    });
  }

  handlePasswordResetRequiredException() {
    this.set("error", {
      message: `Das Passwort muss zurückgesetzt werden. Wir haben Ihnen Anweisungen an Ihr hinterlegtes E-Mail-Postfach (${this.email}) gesendet.`,
    });
    this.amplify.Auth.forgotPassword(this.email)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }
  handleUserNotConfirmedException() {
    this.amplify.Auth.resendSignUp(this.email)
      .then(() => {
        this.set("error", {
          message: `Ihr Account wurde noch nicht aktiviert. Wir haben Ihnen den Aktivierungslink erneut an (${this.email}) gesendet!`,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  @action
  createAccount() {
    this.onCreateAccount(...arguments);
  }

  async afterLoginSuccess() {
    let route = getOwner(this).lookup(`route:application`);
    await route.refresh();
    this.set("isLoading", false);
    return;
  }
  @action
  setNewPassword(username, code, new_password) {
    this.set("isLoading", true);
    this.amplify.Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        this.set("challenge", "LOGIN");
        this.set("success", {
          message: "Passwort wurde neu gesetzt!",
        });
        this.set("isLoading", false);
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "LimitExceededException") {
          this.set("error", {
            message:
              "Sie haben zu oft ein neues Passwort angefordert. Bitte versuchen Sie es in ein paar Minuten erneut.",
          });
        }
        this.set("isLoading", false);
      });
  }
  @action
  requestNewPassword(email) {
    console.log("requestNewPassword", email);
    this.set("isLoading", true);
    this.amplify.Auth.forgotPassword(email)
      .then((data) => {
        this.set("challenge", "NEWPASSWORDSEND");
        //this.set('user', 'NEWPASSWORD');
        this.set("isLoading", false);
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "LimitExceededException") {
          this.set("error", {
            message:
              "Sie haben zu oft ein neues Passwort angefordert. Bitte versuchen Sie es in ein paar Minuten erneut.",
          });
        }
        this.set("isLoading", false);
      });
  }
  @action
  forgotPassword() {
    this.set("challenge", "FORGOTPASSWORD");
  }
  @action
  removeErrorMessage() {
    this.set("error", null);
    this.set("success", null);
  }
}
