import Component from "@ember/component";
import { action, computed, observes } from "@ember-decorators/object";

import { set } from "@ember/object";

import { run } from "@ember/runloop";
import query from "fimab-konfigurator/gql/cabinet/findAll";
import { inject as service } from "@ember-decorators/service";
import Cabinet from "fimab-konfigurator/model/cabinet";
import { isEmpty } from "@ember/utils";
import moment from "moment";
import { integer } from "aws-sdk/clients/cloudfront";

export default class AppCabinDimensions extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  mode: boolean = true;

  @service router: any;
  @service amplify: any;
  deliverydate?: string;
  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;
  montageplatte_size?: string;

  @service("order") orderService: any;

  @service("graphql") apolloService: any;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());

    // Lieferdatum errechnen ----------------
    this.weekday = parseInt(moment().format("d"));
    this.hour = parseInt(moment().format("k"));
    if (this.hour >= 16) {
      if (this.weekday === 6) {
        this.weekday = 0;
      } else {
        this.weekday++;
      }
      this.morgen = " morgen ";
      this.dayplus = 1;
    }

    if (this.weekday === 0) {
      this.daystoadd = 11 + this.dayplus; // +7 Tage weil 3 Wochenende!
    } else if (this.weekday === 6) {
      this.daystoadd = 12 + this.dayplus;
    } else {
      this.daystoadd = 13 + this.dayplus;
    }

    let feiertage = [
      "15.04.2022",
      "18.04.2022",
      "01.05.2022",
      "26.05.2022",
      "06.06.2022",
      "16.06.2022",
      "25.07.2022",
      "26.07.2022",
      "27.07.2022",
      "28.07.2022",
      "29.07.2022",
      "30.07.2022",
      "31.07.2022",
      "01.08.2022",
      "02.08.2022",
      "03.08.2022",
      "04.08.2022",
      "05.08.2022",
      "06.08.2022",
      "08.08.2022",
      "15.08.2022",
      "20.09.2022",
      "03.10.2022",
      "31.10.2022",
      "01.11.2022",
      "16.11.2022",
      "25.12.2022",
      "26.12.2022",
    ];
    let delay: number = 0;

    let mom = moment();
    let u = 0;

    while (u <= this.daystoadd) {
      mom.add(1, "days");

      if (feiertage.indexOf(mom.format("DD.MM.Y")) < 1) {
        u++;
      }
    }

    if (parseInt(mom.format("d")) === 5) {
      mom.add(3, "days");
    } else if (parseInt(mom.format("d")) === 6) {
      mom.add(2, "days");
    } else if (parseInt(mom.format("d")) === 0) {
      mom.add(1, "days");
    }

    this.deliverydate = mom.format("DD.MM.Y");
    // Lieferdatum errechnen ----------------
  }

  @computed()
  get cabinets() {
    return this.apollo
      .query(
        {
          query,
        },
        "cabinets"
      )
      .then((cabins) => {
        return cabins.map((cabinet) => {
          return new Cabinet(cabinet);
        });
      });
  }

  isValidNumber(input) {
    let regex = /^\d+$/;

    return regex.test(input);
  }

  @computed("model.moduleDefinition.Breite")
  get breiteIsValid() {
    if (this.amplify.currentUser !== null) {
      if (this.amplify.currentUser.role === "Admin") {
        return true;
      }
    }
    return (
      this.isValidNumber(this.get("model.moduleDefinition.Breite")) &&
      this.get("model.moduleDefinition.Breite") >=
        this.get("model.cabinet.width.min") &&
      this.get("model.moduleDefinition.Breite") <
        this.get("model.cabinet.width.max") + 1
    );
  }
  @computed("model.moduleDefinition.Hoehe")
  get hoeheIsValid() {
    if (this.amplify.currentUser !== null) {
      if (this.amplify.currentUser.role === "Admin") {
        return true;
      }
    }
    return (
      this.isValidNumber(this.get("model.moduleDefinition.Hoehe")) &&
      this.get("model.moduleDefinition.Hoehe") >=
        this.get("model.cabinet.height.min") &&
      this.get("model.moduleDefinition.Hoehe") <
        this.get("model.cabinet.height.max") + 1
    );
  }
  @computed("model.moduleDefinition.Laenge")
  get laengeIsValid() {
    if (this.amplify.currentUser !== null) {
      if (this.amplify.currentUser.role === "Admin") {
        return true;
      }
    }
    return (
      this.isValidNumber(this.get("model.moduleDefinition.Laenge")) &&
      this.get("model.moduleDefinition.Laenge") >=
        this.get("model.cabinet.laenge.min") &&
      this.get("model.moduleDefinition.Laenge") <
        this.get("model.cabinet.laenge.max") + 1
    );
  }
  @computed("model.name")
  get NameIsValid() {
    return this.get("model.name.length") >= 1;
  }

  didReceiveAttrs() {
    if (this.stepsDone.step1) {
      set(this, "mode", false);
    }
    run.later(() => {
      if (
        this.get("laengeIsValid") &&
        this.get("hoeheIsValid") &&
        this.get("breiteIsValid")
      ) {
        this.set("showNext", true);
      }
    }, 100);
  }

  isValid() {
    return true;
  }

  @observes(
    "model.moduleDefinition.Breite",
    "model.moduleDefinition.Hoehe",
    "model.moduleDefinition.Laenge",
    "model.name"
  )
  onChange() {
    if (
      this.get("laengeIsValid") &&
      this.get("hoeheIsValid") &&
      this.get("breiteIsValid") &&
      this.get("NameIsValid")
    ) {
      this.set("showNext", true);
    }
    return true;
  }

  @computed(
    "model.moduleDefinition.Breite",
    "model.moduleDefinition.Hoehe",
    "model.moduleDefinition.Laenge",
    "model.name"
  )
  get montagesizeBreite() {
    if (
      this.get("model.moduleDefinition.Breite") < 1 ||
      this.get("model.cabinet.id") == "cjq857brf00pe0744sjw8x1p2"
    ) {
      return 0;
    }
    if (this.get("model.cabinet.id") == "cjq857a6500pb0744f2r3yza8") {
      return this.get("model.moduleDefinition.Breite") - 80;
    }
    return this.get("model.moduleDefinition.Breite") - 80;
  }

  @computed(
    "model.moduleDefinition.Breite",
    "model.moduleDefinition.Hoehe",
    "model.moduleDefinition.Laenge",
    "model.name"
  )
  get montagesizeHoehe() {
    if (
      this.get("model.moduleDefinition.Hoehe") < 1 ||
      this.get("model.cabinet.id") == "cjq857brf00pe0744sjw8x1p2"
    ) {
      return 0;
    }
    console.log("Hoehe" + this.get("model.cabinet.id"));
    if (this.get("model.cabinet.id") == "cjq857a6500pb0744f2r3yza8") {
      return this.get("model.moduleDefinition.Hoehe") - 34;
    } else {
      return this.get("model.moduleDefinition.Hoehe") - 30;
    }
  }

  @action
  resetKonfiguration() {
    this.set("mode", true);
    this.set("showNext", false);
    this.set("stepsDone", {});

    this.router.transitionTo("konfiguratorinit");
  }

  @action
  selectCabinet(model, cabinet) {
    this.toggleProperty("mode");
    this.set("model.cabinet", cabinet);
    console.log(cabinet);
    if (cabinet.id == "cjq856wif00p507443n0jvtlq") {
      this.set("model.moduleDefinition.Cabin", 1);
      this.set("model.moduleDefinition.model", "model1_right");
    }
    if (cabinet.id == "cjq85767h00p80744srj85e7a") {
      this.set("model.moduleDefinition.Cabin", 2);
      this.set("model.moduleDefinition.model", "model1_left");
    }
    if (cabinet.id == "cjq857a6500pb0744f2r3yza8") {
      this.set("model.moduleDefinition.Cabin", 3);
      this.set("model.moduleDefinition.model", "model_2");
    }
    if (cabinet.id == "cjq857brf00pe0744sjw8x1p2") {
      this.set("model.moduleDefinition.Cabin", 4);
      this.set("model.moduleDefinition.model", "model_3");
    }

    this.orderService.cacheOrder.perform(this.get("model"));

    this.router.transitionTo("konfigurator", this.get("model"));
  }

  @action
  toggleDetail() {
    this.toggleProperty("mode");
  }
}
