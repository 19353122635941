import Service from "@ember/service";
import { computed } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import Amplify from "aws-amplify";
import { privateEncrypt } from "crypto";

export default class Calculation extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  async getCalculatedPrice(order) {
    const rawResponse = await fetch(
      "https://api.armario.de/api/priceconfigs/calc",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(order),
      }
    );
    const content = await rawResponse.json();
    this.set("amplify.preis", content.cost);
    return content;
  }

  @service() amplify;

  breite: number = 0;
  hoehe: number = 0;
  tiefe: number = 0;
  material: number = 0;
  farbe: boolean = false;
  ausschnitte: number = 0;

  variables: object = {
    stahlblech: 0.8,
    edelstahlblech: 2.82,
    feuerverzinkt: 0.88,
    MGKZuschlag: 0.35,
    vertriebszuschlag: 0.3,
    laserHour: 120,
    laserSpeed: 6700,
    cutting: 0.75,
    abkantenHour: 80,
    abkantenSpeed: 1.5,
    weldingHour: 60.0,
    weldingSpeed: 100,
    steelHour: 45,
    steelSpeed: 100,
    vasteelHour: 45,
    vasteelSpeed: 20,
    powderCoating: 34,
    sealingMaterial: 2.19,
  };

  zGewichtSchrank: number = 0;
  zGewichtTuer: number = 0;
  zGewichtTuerProfile: number = 0;
  zGewichtTuerMontage: number = 0;

  zKostenSchrank: number = 0;
  zKostenTuer: number = 0;
  zKostenTuerProfile: number = 0;
  zKostenTuerMontage: number = 0;

  @computed("material")
  get materialFactor() {
    return this.material == 0
      ? this.variables.stahlblech
      : this.variables.edelstahlblech;
  }

  @computed(
    "variables.{stahlblech,edelstahlblech,feuerverzinkt,MGKZuschlag,vertriebszuschlag}",
    "breite",
    "hoehe",
    "tiefe",
    "materialFactor",
    "farbe"
  )
  get materialkosten() {
    const MGKZuschlag: number = this.variables.MGKZuschlag;

    this.zGewichtSchrank =
      (((this.breite + this.tiefe * 2 + 120) *
        (this.hoehe + this.tiefe * 2 + 120)) /
        10000) *
      0.02 *
      8;
    this.zGewichtTuer =
      (((this.breite + 40) * (this.hoehe + 40)) / 10000) * 0.02 * 8;
    this.zGewichtTuerProfile = ((this.hoehe * 40 * 2) / 10000) * 0.02 * 8;
    this.zGewichtTuerMontage =
      (((this.breite - 40) * (this.hoehe - 40)) / 10000) * 0.02 * 8;

    this.zKostenSchrank =
      this.zGewichtSchrank * this.materialFactor * (1 + MGKZuschlag);
    this.zKostenTuer =
      this.zGewichtTuer * this.materialFactor * (1 + MGKZuschlag);
    this.zKostenTuerProfile =
      this.zGewichtTuerProfile * this.materialFactor * (1 + MGKZuschlag);
    this.zKostenTuerMontage =
      this.zGewichtTuerMontage *
      this.variables.feuerverzinkt *
      (1 + MGKZuschlag);

    return (
      this.zKostenSchrank +
      this.zKostenTuer +
      this.zKostenTuerProfile +
      this.zKostenTuerMontage
    );
  }

  laserSchrankStrecke: number = 0;
  laserTuerStrecke: number = 0;
  laserTuerProfileStrecke: number = 0;
  laserMontageStrecke: number = 0;

  laserSchrankTime: number = 0;
  laserTuerTime: number = 0;
  laserTuerProfileTime: number = 0;
  laserMontageTime: number = 0;

  laserSchrankCost: number = 0;
  laserTuerCost: number = 0;
  laserTuerProfileCost: number = 0;
  laserMontageCost: number = 0;

  @computed("materialkosten")
  get laser() {
    const hour = this.variables.laserHour;
    const speed = this.variables.laserSpeed;

    this.laserSchrankStrecke = (this.breite + this.hoehe + this.tiefe * 4) * 2;
    this.laserTuerStrecke = (this.breite + this.hoehe + 20 * 4) * 2;
    this.laserTuerProfileStrecke = (this.hoehe + 40) * 4;
    this.laserMontageStrecke = (this.breite + this.hoehe - 40 * 4) * 2;

    this.laserSchrankTime = this.laserSchrankStrecke / speed;
    this.laserTuerTime = this.laserTuerStrecke / speed;
    this.laserTuerProfileTime = this.laserTuerProfileStrecke / speed;
    this.laserMontageTime = this.laserMontageStrecke / speed;

    this.laserSchrankCost = (this.laserSchrankTime * hour) / 60;
    this.laserTuerCost = (this.laserTuerTime * hour) / 60;
    this.laserTuerProfileCost = (this.laserTuerProfileTime * hour) / 60;
    this.laserMontageCost = (this.laserMontageTime * hour) / 60;

    return (
      this.laserSchrankCost +
      this.laserTuerCost +
      this.laserTuerProfileCost +
      this.laserMontageCost +
      14.4
    );
  }

  @computed("ausschnitte", "materialkosten")
  get cutting() {
    return this.ausschnitte * this.variables.cutting;
  }

  @computed("laser")
  get abkanten() {
    return 66.6666666666667;
  }

  weldingSchrankStrecke: number = 0;
  weldingTuerStrecke: number = 0;
  weldingTuerProfileStrecke: number = 0;

  weldingSchrankTime: number = 0;
  weldingTuerTime: number = 0;
  weldingTuerProfileTime: number = 0;

  weldingSchrankCost: number = 0;
  weldingTuerCost: number = 0;
  weldingTuerProfileCost: number = 0;

  @computed("abkanten")
  get welding() {
    const hour = this.variables.weldingHour;
    const speed = this.variables.weldingSpeed;

    this.weldingSchrankStrecke = (this.tiefe + 120) * 4;
    this.weldingTuerStrecke = 20 * 4;
    this.weldingTuerProfileStrecke = (this.hoehe / 200) * 4 * 10;

    this.weldingSchrankTime = this.weldingSchrankStrecke / speed;
    this.weldingTuerTime = this.weldingTuerStrecke / speed;
    this.weldingTuerProfileTime = this.weldingTuerProfileStrecke / speed;

    this.weldingSchrankCost = (this.weldingSchrankTime * hour) / 60;
    this.weldingTuerCost = (this.weldingTuerTime * hour) / 60;
    this.weldingTuerProfileCost = (this.weldingTuerProfileTime * hour) / 60;

    return (
      this.weldingSchrankCost +
      this.weldingTuerCost +
      this.weldingTuerProfileCost +
      10
    );
  }

  @computed("material")
  get schleifenFactor() {
    return this.material == 0
      ? this.variables.steelSpeed
      : this.variables.vasteelSpeed;
  }

  grindSchrankTime: number = 0;
  grindTuerTime: number = 0;

  grindTuerCost: number = 0;
  grindSchrankCost: number = 0;

  @computed("welding", "schleifenFactor", "material")
  get schleifen() {
    const speed = this.get("schleifenFactor");
    const hour = this.variables.steelHour;

    const additionalCosts = this.material == 0 ? 3.75 : 0;

    this.grindSchrankTime = this.weldingSchrankStrecke / speed;
    this.grindTuerTime = this.weldingTuerStrecke / speed;

    this.grindSchrankCost = (this.grindSchrankTime * hour) / 60;
    this.grindTuerCost = (this.grindTuerTime * hour) / 60;

    return this.grindSchrankCost + this.grindTuerCost + additionalCosts;
  }

  areaSchrank: number = 0;
  areaTuer: number = 0;
  areaSchrankCost: number = 0;
  areaTuerCost: number = 0;

  @computed("schleifen")
  get powderCoating() {
    const price = this.variables.powderCoating;

    this.areaSchrank =
      ((this.breite + this.tiefe * 2 + 120) *
        (this.hoehe + this.tiefe * 2 + 120)) /
      1000000;
    this.areaTuer = ((this.breite + 40) * (this.hoehe + 40)) / 1000000;

    this.areaSchrankCost = this.areaSchrank * price;
    this.areaTuerCost = this.areaTuer * price;

    return this.areaSchrankCost + this.areaTuerCost + 12;
  }

  @computed("powderCoating")
  get sealingMaterial() {
    const price = this.variables.sealingMaterial;
    return ((this.breite * this.hoehe * 4) / 1000000) * price;
  }

  @computed(
    "powderCoating",
    "breite",
    "hoehe",
    "tiefe",
    "materialFactor",
    "farbe",
    "ausschnitte"
  )
  get gesamtPreis() {
    let sum =
      this.get("materialkosten") +
      this.get("abkanten") +
      this.get("laser") +
      this.get("cutting") +
      this.get("welding") +
      this.get("schleifen") +
      this.get("sealingMaterial") +
      35.57 +
      15;
    if (this.farbe.code !== "keine") {
      sum = sum + this.get("powderCoating");
    }
    return sum;
  }
  @computed(
    "gesamtPreis",
    "breite",
    "hoehe",
    "tiefe",
    "materialFactor",
    "farbe",
    "ausschnitte"
  )
  get gesamtPreisMitZuschlag() {
    if (
      this.get("breite") > 0 &&
      this.get("hoehe") > 0 &&
      this.get("tiefe") > 0
    ) {
      const preis =
        this.get("gesamtPreis") * (1 + this.variables.vertriebszuschlag);
      this.set("amplify.preis", preis);
      return preis;
    } else {
      return 0;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module "@ember/service" {
  interface Registry {
    calculation: Calculation;
  }
}
