import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";

import query from "fimab-konfigurator/gql/werbebanner/findAll";

export default class AppNav extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  openSearchModal?: Function;
  modal?: boolean = false;
  supportModal?: boolean = false;
  public defaultimage?: string = "default.jpg";
  public banner: any;

  @service amplify: any;
  @service router: any;

  @service("graphql") apolloService: any;

  constructor() {
    super(...arguments);
    this.initWhatever();
    this.set("apollo", this.get("apolloService").createQueryManager());
    this.set("banner", this.werbebanner());
  }

  async initWhatever() {
    jQuery(document).ready(function () {
      // jQuery(".box-werbebanner").html("test");
      var slideCount = jQuery("#slider ul li").length;
      var slideWidth = jQuery("#slider ul li").width();
      var slideHeight = jQuery("#slider ul li").height();
      var sliderUlWidth = slideCount * slideWidth;

      jQuery("#slider").css({ width: slideWidth, height: slideHeight });
      jQuery("#slider ul").css({
        width: sliderUlWidth,
        marginLeft: -slideWidth,
      });
      jQuery("#slider ul li:last-child").prependTo("#slider ul");

      setInterval(function () {
        moveRight();
      }, 10000);

      function moveRight() {
        $("#slider ul").animate(
          {
            left: -slideWidth,
          },
          200,
          function () {
            $("#slider ul li:first-child").appendTo("#slider ul");
            $("#slider ul").css("left", "");
          }
        );
      }
    });
  }

  werbebanner() {
    return this.apollo
      .query(
        {
          query,
        },
        "banners"
      )
      .then((banner) => {
        return banner;
      });
  }

  @action
  openModal() {
    this.openSearchModal();
  }
  @action
  loginModal() {
    this.set("modal", true);
  }
  @action
  konfigurator() {
    this.router.transitionTo("konfigurator");
  }
  @action
  overview() {
    this.router.transitionTo("konfiguratoroverview");
  }
  @action
  openSupportModal() {
    this.toggleProperty("supportModal");
  }
  @action
  onHide() {
    this.set("supportModal", false);
  }
  @action
  close() {
    this.set("modal", false);
  }

  @action
  openChat() {
    document.getElementById("userlike-button-symbol").click();
    this.set("modal", false);
  }
}
