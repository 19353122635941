import Controller from "@ember/controller";
import { computed, action, observes } from "@ember-decorators/object";
import { set } from "@ember/object";
import { inject as service } from "@ember-decorators/service";
import { isEmpty } from "@ember/utils";
import { next } from '@ember/runloop';


import {
  ComponentDefinition,
  CreateDrag,
  ComponentInstance,
  CabinetDefinition,
  CabinetConfiguration,
  CabinetMaterial,
  CabinetStructure,
} from "./types";
import componentList from "./components";
import cabinetDefinitions from "./cabinets";
import { task, dropTask } from "ember-concurrency-decorators";
const localStoragePrefix = "fimab";
// Default-Konfiguration fuer Development

export default class Konfigurator extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service("order") orderService: any;
  @service() calculation: any;
  @service("graphql") apolloService: any;

  // Default-Konfiguration fuer Development

  successMessageAngebot = false;
  cabinetConfiguration = null;


  queryParams: [string] = ["step"];

  step: string = "1";

  showNext: boolean = false;

  modalOpen: boolean = true;

  showAccountCreate: boolean = false;

  exportXMLData: string = "";
  exportXMLModal: boolean = false;

  isLoading: boolean = true;

  stepsDone: object = {
    step1: false,
    step2: false,
    step3: false,
  };

  toggle: boolean = false;

  montageplattebreite: number = 0;
  montageplattehoehe: number = 0;

  @computed(
    "toggle",
    "model.cabinet",
    "model.color",
    "model.moduleDefinition.Breite",
    "model.moduleDefinition.Hoehe",
    "model.moduleDefinition.Laenge",
    "model.name",
    "model.moduleDefinition.model",
    "montageplattebreite",
    "montageplattehoehe"

  )
  get cabinetConfiguration() {
    if (this.model.cabinet === null) {
      return false;
    }

    let count = 0;

    this.set("model.moduleDefinition.sides.VORNE", {
      type: "VORNE",
      minX: this.model.cabinet.sperrflaechen.VORNE.LINKS,
      minY: this.model.cabinet.sperrflaechen.VORNE.OBEN,
      maxX:
        this.model.moduleDefinition.Breite -
        this.model.cabinet.sperrflaechen.VORNE.RECHTS,
      maxY:
        this.model.moduleDefinition.Hoehe -
        this.model.cabinet.sperrflaechen.VORNE.OBEN,
      width: parseInt(this.model.moduleDefinition.Breite),
      height: parseInt(this.model.moduleDefinition.Hoehe),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.VORNE.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.VORNE.components")) {
      count =
        count +
        this.get("model.moduleDefinition.sides.VORNE.components.length");
    }

    this.set("model.moduleDefinition.sides.HINTEN", {
      type: "HINTEN",
      minX: this.model.cabinet.sperrflaechen.HINTEN.LINKS,
      minY: this.model.cabinet.sperrflaechen.HINTEN.OBEN,
      maxX:
        this.model.moduleDefinition.Breite -
        this.model.cabinet.sperrflaechen.HINTEN.RECHTS,
      maxY:
        this.model.moduleDefinition.Hoehe -
        this.model.cabinet.sperrflaechen.HINTEN.OBEN,
      width: parseInt(this.model.moduleDefinition.Breite),
      height: parseInt(this.model.moduleDefinition.Hoehe),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.HINTEN.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.HINTEN.components")) {
      count =
        count +
        this.get("model.moduleDefinition.sides.HINTEN.components.length");
    }
    this.set("model.moduleDefinition.sides.MONTAGEPLATTE", {
      type: "MONTAGEPLATTE",
      minX: this.model.cabinet.sperrflaechen.MONTAGEPLATTE.LINKS,
      minY: this.model.cabinet.sperrflaechen.MONTAGEPLATTE.OBEN,
      maxX:
        this.model.moduleDefinition.Breite -
        this.model.cabinet.sperrflaechen.MONTAGEPLATTE.RECHTS,
      maxY:
        this.model.moduleDefinition.Hoehe -
        this.model.cabinet.sperrflaechen.MONTAGEPLATTE.OBEN,
      width: parseInt(this.model.moduleDefinition.Breite),
      height: parseInt(this.model.moduleDefinition.Hoehe),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.MONTAGEPLATTE.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.MONTAGEPLATTE.components")) {
      count =
        count +
        this.get(
          "model.moduleDefinition.sides.MONTAGEPLATTE.components.length"
        );
    }
    this.set("model.moduleDefinition.sides.LINKS", {
      type: "LINKS",
      minX: this.model.cabinet.sperrflaechen.LINKS.LINKS,
      minY: this.model.cabinet.sperrflaechen.LINKS.OBEN,
      maxX:
        this.model.moduleDefinition.Laenge -
        this.model.cabinet.sperrflaechen.LINKS.RECHTS,
      maxY:
        this.model.moduleDefinition.Hoehe -
        this.model.cabinet.sperrflaechen.LINKS.OBEN,
      width: parseInt(this.model.moduleDefinition.Laenge),
      height: parseInt(this.model.moduleDefinition.Hoehe),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.LINKS.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.LINKS.components")) {
      count =
        count +
        this.get("model.moduleDefinition.sides.LINKS.components.length");
    }
    this.set("model.moduleDefinition.sides.RECHTS", {
      type: "RECHTS",
      minX: this.model.cabinet.sperrflaechen.RECHTS.LINKS,
      minY: this.model.cabinet.sperrflaechen.RECHTS.OBEN,
      maxX:
        this.model.moduleDefinition.Laenge -
        this.model.cabinet.sperrflaechen.RECHTS.RECHTS,
      maxY:
        this.model.moduleDefinition.Hoehe -
        this.model.cabinet.sperrflaechen.RECHTS.OBEN,
      width: parseInt(this.model.moduleDefinition.Laenge),
      height: parseInt(this.model.moduleDefinition.Hoehe),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.RECHTS.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.RECHTS.components")) {
      count =
        count +
        this.get("model.moduleDefinition.sides.RECHTS.components.length");
    }
    this.set("model.moduleDefinition.sides.OBEN", {
      type: "OBEN",
      minX: this.model.cabinet.sperrflaechen.OBEN.LINKS,
      minY: this.model.cabinet.sperrflaechen.OBEN.UNTEN,
      maxX:
        this.model.moduleDefinition.Breite -
        this.model.cabinet.sperrflaechen.OBEN.RECHTS,
      maxY:
        this.model.moduleDefinition.Laenge -
        this.model.cabinet.sperrflaechen.OBEN.OBEN,
      width: parseInt(this.model.moduleDefinition.Breite),
      height: parseInt(this.model.moduleDefinition.Laenge),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.OBEN.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.OBEN.components")) {
      count =
        count + this.get("model.moduleDefinition.sides.OBEN.components.length");
    }
    this.set("model.moduleDefinition.sides.UNTEN", {
      type: "UNTEN",
      minX: this.model.cabinet.sperrflaechen.UNTEN.LINKS,
      minY: this.model.cabinet.sperrflaechen.UNTEN.UNTEN,
      maxX:
        this.model.moduleDefinition.Breite -
        this.model.cabinet.sperrflaechen.UNTEN.RECHTS,
      maxY:
        this.model.moduleDefinition.Laenge -
        this.model.cabinet.sperrflaechen.UNTEN.OBEN,
      width: parseInt(this.model.moduleDefinition.Breite),
      height: parseInt(this.model.moduleDefinition.Laenge),
      image: "",
      components:
        this.get("model.moduleDefinition.sides.UNTEN.components") || [],
    });
    if (this.get("model.moduleDefinition.sides.UNTEN.components")) {
      count =
        count +
        this.get("model.moduleDefinition.sides.UNTEN.components.length");
    }

    this.set("model.moduleDefinition.ausschnitte", count);

    let model, sperrflaeche;
    if (this.get("model.moduleDefinition.Cabin") == 1) {
      model = "model1_right";
    }
    if (this.get("model.moduleDefinition.Cabin") == 2) {
      model = "model1_left";
    }
    if (this.get("model.moduleDefinition.Cabin") == 3) {
      model = "model_2";
      sperrflaeche = {
        x: this.get("model.moduleDefinition.Breite") / 2 - 110,
        y: 0,
        w: 220,
        h: this.get("model.moduleDefinition.Hoehe"),
      };
    }
    if (this.get("model.moduleDefinition.Cabin") == 4) {
      model = "model_3";
    }

    const config = {
      id: this.get("model.name"),
      model: model,
      w: parseInt(this.get("model.moduleDefinition.Breite")),
      h: parseInt(this.get("model.moduleDefinition.Hoehe")),
      d: parseInt(this.get("model.moduleDefinition.Laenge")),
      cabinetId: this.get("model.name"),
      material: CabinetMaterial.EDELSTAHL,
      structure: CabinetStructure.GLATTGLAENZEND,
      color: this.get("model.color.rgb_hex") || "#cccccc",
      sides: [
        {
          ...this.get("model.moduleDefinition.sides.VORNE"),

          lockArea: sperrflaeche,
        },
        {
          ...this.get("model.moduleDefinition.sides.LINKS"),
        },
        {
          ...this.get("model.moduleDefinition.sides.RECHTS"),
        },
        {
          ...this.get("model.moduleDefinition.sides.OBEN"),
        },
        {
          ...this.get("model.moduleDefinition.sides.UNTEN"),
        },
        {
          ...this.get("model.moduleDefinition.sides.HINTEN"),
        },
        {
          ...this.get("model.moduleDefinition.sides.MONTAGEPLATTE"),
        },
      ],
    };
    next(this, function() {
      if (this.model.moduleDefinition.Cabin === 1 || this.model.moduleDefinition.Cabin === 2){
      
        this.set ("montageplattebreite" , this.model.moduleDefinition.Breite - 80);
        this.set ("montageplattehoehe" , this.model.moduleDefinition.Hoehe - 30);        
      } else {
        
        this.set ("montageplattebreite" , this.model.moduleDefinition.Breite - 80);
        this.set ("montageplattehoehe" , this.model.moduleDefinition.Hoehe - 34);
      }
    });
    
    

    return config;
  }

  constructor() {
    super(...arguments);

    this.loadConfiguration();

    window.FIMAB_CONFIGURATOR = {
      setCabinetConfiguration: () => {
        return true;
      },
      updateComponent: () => {
        return true;
      },
      addComponent: () => {
        return true;
      },
      deleteComponent: () => {
        return true;
      },
      showSurfaceSide: () => {
        return true;
      },
    };

    this.set("apollo", this.get("apolloService").createQueryManager());
    const cachedStepsDone = this.amplify.Cache.getItem("stepsDone");
    if (!isEmpty(cachedStepsDone)) {
      this.set("stepsDone", cachedStepsDone);
    }
    this.get("amplify").on("account:login", this, "onAccountLogin");

    this.get("amplify").on("3DView:loaded", this, () => {
      this.set("isLoading", false);
    });
    if (this.get("amplify.isAuthenticated")) {
      this.set("modalOpen", false);
    }
  }

  loadConfiguration() {
    const instanceJson = localStorage.getItem(
      localStoragePrefix + ".cabinetConfiguration"
    );
    if (instanceJson) {
      const parsedConfiguration = JSON.parse(instanceJson);

      this.cabinetConfiguration = parsedConfiguration;
    }
  }

  saveConfiguration(key: string, data: object) {
    localStorage.setItem(localStoragePrefix + "." + key, JSON.stringify(data));
  }

  @action
  onComponentCount(count) {
    this.set("model.moduleDefinition.ausschnitte", count);
    this.orderService.cacheOrder.perform(this.model);
  }

  @action onInstanceChange(instances: ComponentInstance[]) {
    /*let length = 
    instances.sides.OBEN.components.length + 
    instances.sides.UNTEN.components.length + 
    instances.sides.LINKS.components.length + 
    instances.sides.RECHTS.components.length + 
    instances.sides.VORNE.components.length + 
    instances.sides.HINTEN.components.length + 
    instances.sides.MONTAGEPLATTE.components.length; 
    this.set('model.moduleDefinition.ausschnitte', length);*/

    this.cacheOrderInternal.perform(instances);
  }

  @task()
  *cacheOrderInternal(instances) {
    this.set(
      "model.moduleDefinition.sides.VORNE.components",
      instances.sides[0].components
    );
    this.set(
      "model.moduleDefinition.sides.LINKS.components",
      instances.sides[1].components
    );
    this.set(
      "model.moduleDefinition.sides.RECHTS.components",
      instances.sides[2].components
    );
    this.set(
      "model.moduleDefinition.sides.OBEN.components",
      instances.sides[3].components
    );
    this.set(
      "model.moduleDefinition.sides.UNTEN.components",
      instances.sides[4].components
    );
    this.set(
      "model.moduleDefinition.sides.HINTEN.components",
      instances.sides[5].components
    );
    this.set(
      "model.moduleDefinition.sides.MONTAGEPLATTE.components",
      instances.sides[6].components
    );

    let temp = yield this.orderService.cacheOrder.perform(this.model);
  }

  didRecieveAttributes() {
    if (this.get("step") > 1 && isEmpty(this.get("model.share"))) {
      this.set("step", 1);
    }
    if (this.get("model.moduleDefinition.meta") !== null) {
      this.set("step", 2);
    }
  }

  willDestroy() {
    this.get("amplify").off("account:login");
    this.get("amplify").off("3DView:loaded");
  }

  onAccountLogin() {
    this.set("modalOpen", false);
  }

  async cacheCurrentConfiguration() {
    this.orderService.cacheOrder.perform(this.get("model"));
  }

  @observes("stepsDone.step1", "stepsDone.step2", "stepsDone.step3")
  onChangeStepsDone() {
    this.amplify.Cache.setItem("stepsDone", this.stepsDone);
  }

  @observes("step")
  onStepChange() {
    let step = this.get("step");
    if (
      !isEmpty(this.get("model.moduleDefinition")) &&
      parseInt(step) > 1 &&
      !isEmpty(this.get("model"))
    ) {
      this.set("model.moduleDefinition.currentStep", parseInt(step));
    }

    ga("send", "pageview", `step${step}`);

    if (this.get("step") == "4") {
      if (typeof unityInstance !== "undefined") {
        unityInstance.SendMessage(
          "[CabinetConfigurator]",
          "RequestScreenshots",
          85
        );
      }
    }
    if (this.get("step") == "1" || this.get("step") == "4") {
      this.amplify.trigger("3DView:deactivateCamera");
    } else {
      this.amplify.trigger("3DView:activateCamera");
    }
    this.cacheCurrentConfiguration();
  }

  @computed("model")
  get configurationUrl() {
    return `${window.origin}/konfigurator/${this.model.id}`;
  }

  @computed("showAccountCreate")
  get modalSize() {
    return this.showAccountCreate ? "lg" : "sm";
  }

  @action
  openModal() {
    this.set("modalOpen", true);
  }

  @action
  showNextAction() {
    this.set("showNext", true);
  }

  @action
  async next() {
    let currentStep = parseInt(this.step);
    await this.orderService.saveOrder.perform(this.get("model"));
    if (currentStep == 4) {
      if (typeof unityInstance !== "undefined") {
        unityInstance.SendMessage(
          "[CabinetConfigurator]",
          "RequestScreenshots",
          85
        );
      }

      return;
    }
    currentStep = currentStep + 1;
    if (currentStep == 2) {
      this.set("stepsDone.step1", true);
      this.toggleProperty("toggle");
    }
    if (currentStep == 3) {
      this.set("stepsDone.step2", true);
    }
    if (currentStep == 4) {
      this.set("stepsDone.step3", true);
    }

    this.cacheCurrentConfiguration();
    set(this, "step", currentStep.toString());
  }

  isUpdateable() {
    if (isEmpty(this.get("model.id"))) {
      return false;
    }
    return true;
  }
  isSaveable() {
    if (isEmpty(this.get("amplify.currentUser.id"))) {
      return false;
    }
    if (isEmpty(this.get("model.name"))) {
      return false;
    }
    return true;
  }

  @action
  async saveOrder() {
    await this.orderService.createOrder(this.get("model"));
    this.set("successMessageAngebot", true);
  }

  async cleanup() {
    this.amplify.Cache.removeItem("currentOrder");
    this.amplify.Cache.removeItem("stepsDone");

    this.set("stepsDone", {
      step1: false,
      step2: false,
      step3: false,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    konfigurator: Konfigurator;
  }
}
