import Service from '@ember/service';

export default class Konfigurator extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  constructor() {
    super(...arguments);
    window.addEventListener("message", this.onMessage, false);
  }

  /**
   * 
   * @param event 
   */
  onMessage(event: any) {
    
    if (event.origin !== window.origin) {
      throw new Error("Event origin does not match")
    }

  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'konfigurator': Konfigurator;
  }
}
