import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember-decorators/service';
import Order from 'fimab-konfigurator/model/order';
import CREATE_ORDER from 'fimab-konfigurator/gql/order/createOrder';
import uuid from 'uuid/v4';
import {
  isEmpty
} from '@ember/utils';

export default class KonfiguratorInit extends Route.extend({
  // anything which *must* be merged to prototype here
}) {

  @service('amplify') amplify: any;
  @service('order') orderService: any;
  @service('graphql') apolloService: any;

  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
  async createOrder() {
    let order = new Order({
      share: uuid(),
      name: "",
      color:{
        code: "keine",
        name: "Light grey",
        rgb_approx: "215-215-215",
        rgb_hex: "#D7D7D7",
      },
      isPublic: false,
      material: "Stahlblech",
      finish: '',
      status: "",
      count: 1,
      moduleDefinition: {
        Laenge: '',
        Breite: '',
        Hoehe: '',
        Sockel: '',
        Cabin: 1,
        sides: {}
      }
    });
    delete order.createdAt;
    const variables: any = {
      data: order
    }
    if (!isEmpty(this.get('amplify.currentUser.id'))) {
      variables.data.user = {
        connect: {
          id: this.get('amplify.currentUser.id')
        }
      }
    }
    try {
      let savedOrder = await this.apollo.mutate({
        mutation: CREATE_ORDER,
        variables
      }, 'createOrder');
      let order = new Order(savedOrder)
      this.orderService.cacheOrder.perform(order);
      this.amplify.Cache.removeItem('stepsDone');
      return order;
    } catch (e) {
      console.error("Error saving new Order", e);
    }
  }
  async beforeModel() {
  
    let order = await this.createOrder();
    return this.transitionTo('konfigurator', order.share);
  }
}
