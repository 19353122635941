import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import { set } from "@ember/object";
import { inject as service } from "@ember-decorators/service";
import updateOrder from "fimab-konfigurator/gql/order/updateOrder";
import { integer } from "aws-sdk/clients/lightsail";
import { isEmpty, isNone } from "@ember/utils";
import query from "fimab-konfigurator/gql/component/findAll";
import { run } from "@ember/runloop";
import { task, enqueueTask } from "ember-concurrency-decorators";
import { attribute } from "@ember-decorators/component";

export default class AppConfiguration extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService: any;
  @service() amplify: any;
  @service("order") orderService: any;
  @service("dragdrop") draggingService: any;

  @attribute("style") style = "height:100%;";

  gameInstance: any;

  step: string;

  cleanEnv: boolean;

  isCabinInitialized: boolean = false;

  isLoaded: boolean = false;
  showlochbilder: boolean = false;

  constructor() {
    super(...arguments);
    this.amplify.on("3DView:initWithCabin", this, "initCabinTask");
    this.amplify.on("3DView:setColor", this, "setColor");
    this.amplify.on("3DView:activateCamera", this, "activateCamera");
    this.amplify.on("3DView:screenshot", this, "screenshot");
    this.amplify.on("3DView:removeColor", this, "removeColor");
    this.amplify.on("3DView:deactivateCamera", this, "deactivateCamera");
    this.amplify.on("3DView:reset", this, "reset3DView");
    this.set("apollo", this.get("apolloService").createQueryManager());
    this.initFocusHandler();
  }

  willDestroyElement() {
    this.amplify.off("3DView:setColor", this, "setColor");
    this.amplify.off("3DView:initWithCabin", this, "initCabinTask");
    this.amplify.off("3DView:screenshot", this, "screenshot");
    this.amplify.off("3DView:removeColor", this, "removeColor");
    this.amplify.off("3DView:activateCamera", this, "activateCamera");
    this.amplify.off("3DView:reset", this, "reset3DView");
    this.amplify.off("3DView:deactivateCamera", this, "deactivateCamera");
    if (typeof unityInstance.Quit == "function") {
      unityInstance.Quit(() => {
        return true;
      });
    }
  }

  reset3DView() {
    this.activateCamera();
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "reloadScene");
    } else {
    }
  }

  screenshot() {
    this.activateCamera();
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "makeScreenshot", "");
    } else {
    }
  }

  initCabinTask(data) {
    this.initCabin.perform(data);
  }

  @task
  *screenshots(screens: any[]) {
    screens = screens.map((item: any) => {
      item.open = false;
      return item;
    });
    yield this.set("model.screens", screens);
    yield this.orderService.saveOrder(this.get("model"));
  }

  @task
  *loadView() {
    this.set("cleanEnv", true);
    this.set("isLoaded", true);
    this.amplify.trigger("3DView:loaded");
    if (this.isCabinInitialized == false) {
      const { Breite, Hoehe, Laenge, Sockel, Cabin } = this.get(
        "model.moduleDefinition"
      );

      if (this.step == "2" || this.step == "3") {
        this.activateCamera();
      }
      if (Breite > 0 && Hoehe > 0) {
        this.initCabin.perform({ Breite, Hoehe, Laenge, Sockel, Cabin });
      }
    }
    return;
  }

  @enqueueTask
  *baugruppen(payload) {
    yield this.apollo.query({ query }, "components").then((components: any) => {
      let bauteile = payload.Baugruppenteile.map((Baugruppenteil: any) => {
        let rauskommt = Object.assign(
          {},
          components.findBy("id", Baugruppenteil.id),
          Baugruppenteil
        );

        return rauskommt;
      });
      bauteile = bauteile.filter((teil) => {
        if (isEmpty(teil.id)) {
          return false;
        } else if (teil.X_Position == 0) {
          return false;
        }
        return true;
      });

      if (!isEmpty(this.model.moduleDefinition)) {
        this.set("model.moduleDefinition.Baugruppenteile", bauteile);

        return this.cacheOrder();
      }

      if (
        this.model.moduleDefinition.Baugruppenteile.length <= bauteile.length
      ) {
      } else {
        return;
      }

      this.set("model.moduleDefinition.Baugruppenteile", bauteile);

      return this.cacheOrder();
    });
  }

  didInsertElement() {
    window.addEventListener(
      "message",
      async (event) => {
        {
          if (event.data == "loaded") {
            this.loadView.perform();
            return;
          }
          try {
            const payload = JSON.parse(event.data);
            if (!isEmpty(payload.meshData) && payload.id == "StlContainer") {
              const rawResponse = await fetch(
                "https://4gpuvheohl.execute-api.eu-central-1.amazonaws.com/prod/download",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    filetype: "stl",
                    customer: `${this.amplify.currentUser.customerId}`,
                    body: payload.meshData,
                    ordernumber: `${this.model.share}`,
                  }),
                }
              );
              const content = await rawResponse.json();

              let a = document.createElement("A");
              a.href = content.url;
              a.download = content.url.substr(content.url.lastIndexOf("/") + 1);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            if (!isEmpty(payload.meshData) && payload.id == "ObjContainer") {
              const rawResponse = await fetch(
                "https://4gpuvheohl.execute-api.eu-central-1.amazonaws.com/prod/download",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    filetype: "obj",
                    customer: `${this.amplify.currentUser.customerId}`,
                    body: payload.meshData,
                    ordernumber: `${this.model.share}`,
                  }),
                }
              );
              const content = await rawResponse.json();

              let a = document.createElement("A");
              a.href = content.url;
              a.download = content.url.substr(content.url.lastIndexOf("/") + 1);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }

            if (!isEmpty(payload.type)) {
              this.set("model.screens", payload.images);
              await this.orderService.saveOrder.perform(this.get("model"));
              this.set("model", this.get("orderService.order"));
            }

            if (!isEmpty(payload._Siteinfo)) {
              this.amplify.trigger("3DView:activeSide", payload._Siteinfo[0]);
              return;
            }
            if (!isEmpty(payload._Screens)) {
              this.screenshots.perform(payload._Screens);
              return;
            }
            if (!isEmpty(payload.Baugruppenteile)) {
              this.baugruppen.perform(payload);
              return;
            }
          } catch (e) {
            console.error(e, event);
          }
        }
      },
      false
    );
    /* const gameInstance = UnityLoader.instantiate("gameContainer", "/3dview/Build/fimab-3d-view.json", {
        Module: {
          onRuntimeInitialized: () => {
            
            this.gameInstance = gameInstance;
            window.gameInstance = gameInstance;
          },
        }
    });*/

    //const gameInstance = UnityLoader.instantiate("gameContainer", "/Build/fimab-3d-view.json", {onProgress: UnityProgress});
  }

  async cacheOrder() {
    const order = this.get("model");
    return this.orderService.cacheOrder.perform(order);
  }

  progress(params: object, state: integer) {
    if (state == 1 && !this.isCabinInitialized) {
    }
  }

  removeColor() {
    this.set("model.color", {
      code: "keine",
      name: "Light grey",
      rgb_approx: "215-215-215",
      rgb_hex: "#D7D7D7",
    });
    this.set("model.finish", "");
    this.cacheOrder();
  }

  async setColor(color: object) {
    this.set("model.color", color);
    if (this.get("model.finish") === "") {
      this.set("model.finish", "Grobstruktur glänzend");
    }
    await this.cacheOrder();
  }
  @action
  exportObj() {
    if (unityInstance) {
      unityInstance.SendMessage("[CabinetConfigurator]", "SendObjContainer");
    }
  }
  @action
  exportStl() {
    if (unityInstance) {
      unityInstance.SendMessage("[CabinetConfigurator]", "SendStlContainer");
    }
  }
  @action
  hideDebugNav() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "hideDebugNav");
    } else {
    }
  }

  @action
  lochbilder() {
    if (unityInstance) {
      if (this.showlochbilder) {
        unityInstance.SendMessage("[CabinetConfigurator]", "SetDisplayMode", 0);
      } else {
        unityInstance.SendMessage("[CabinetConfigurator]", "SetDisplayMode", 1);
      }
      this.toggleProperty("showlochbilder");
    } else {
    }
  }

  showSperrflaechen: boolean = false;

  @action
  sperrflaeche() {
    if (this.gameInstance) {
      if (this.showSperrflaechen) {
        this.gameInstance.SendMessage("GUI", "hideSperrflaeche");
      } else {
        this.gameInstance.SendMessage("GUI", "showSperrflaeche");
      }
      this.toggleProperty("showSperrflaechen");
    } else {
    }
  }
  @action
  standardansicht() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnSTART");
    } else {
    }
  }

  @action
  turnOben() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnOBEN");
    } else {
    }
  }
  @action
  turnUnten() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnUNTEN");
    } else {
    }
  }
  @action
  turnLinks() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnLINKS");
    } else {
    }
  }
  @action
  turnRechts() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnRECHTS");
    } else {
    }
  }
  @action
  turnVorne() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnVORNE");
    } else {
    }
  }
  @action
  turnHinten() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnHINTEN");
    } else {
    }
  }
  @action
  turnMontageplatte() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "turnMONTAGEPLATTE");
    } else {
    }
  }

  activateCamera() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "cameraON");
      this.gameInstance.SendMessage("GUI", "FocusCanvas", "1");
    } else {
    }
  }

  deactivateCamera() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "cameraOFF");
      this.gameInstance.SendMessage("GUI", "FocusCanvas", "0");
    } else {
    }
  }

  @task
  *initCabin(config: object) {
    if (this.gameInstance) {
      this.gameInstance.SendMessage("GUI", "reloadScene");
      this.set("isCabinInitialized", true);
      run.later(() => {
        this.activateCamera();
        this.gameInstance.SendMessage("GUI", "hideDebugNav");
        this.gameInstance.SendMessage(
          "GUI",
          "setCorpus",
          JSON.stringify(config)
        );
        if (typeof this.get("model.color.name") == "string") {
          this.setColor(this.get("model.color"));
        }
        this.initBauteile();

        this.set("cleanEnv", false);
        if (["1", "4"].includes(this.get("step"))) {
          this.deactivateCamera();
        }
      }, 0);
    } else {
    }
  }

  initBauteile() {
    const bauteile = this.get("model.moduleDefinition.Baugruppenteile");
    if (!isNone(bauteile)) {
      bauteile.forEach((element: any) => {
        this.initBauteil.perform(element);
      });
    }
  }

  @enqueueTask()
  *initBauteil(element) {
    this.send("handleDrag", element);
  }

  initFocusHandler() {
    document.addEventListener("click", (e: any) => {
      if (this.gameInstance) {
        if (e.target.id == "#canvas") {
          this.gameInstance.SendMessage("GUI", "FocusCanvas", "1");
        } else {
          this.gameInstance.SendMessage("GUI", "FocusCanvas", "0");
        }
      }
    });
  }

  @action
  handleDrag(object: object) {
    if (isEmpty(object.id)) {
      return;
    }
    if (this.gameInstance) {
      this.amplify.trigger("makro:dropped");
      this.gameInstance.SendMessage(
        "GUI",
        "createProd",
        JSON.stringify(object)
      );
    } else {
    }
  }
  @action
  handleDrag2(object: object) {}
  @action
  dragOverAction(event: Event) {
    event.preventDefault();
    let object = this.get("draggingService.currentItem");
    if (isEmpty(object.id)) {
      return;
    }
    if (this.gameInstance) {
      this.amplify.trigger("makro:dropped");
      this.gameInstance.SendMessage(
        "GUI",
        "createProd",
        JSON.stringify(object)
      );
    } else {
    }
  }
  @action
  dragOutAction() {}
}
