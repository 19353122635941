import ApolloService from "ember-apollo-client/services/apollo";
import { computed } from '@ember-decorators/object';


export default class Graphql extends ApolloService.extend({
  // anything which *must* be merged to prototype here
}) {
  @computed()
  get clientOptions() {
    return {
      link: this.link,
      cache: this.cache,
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'graphql': Graphql
  }
}
