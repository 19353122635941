import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action } from '@ember-decorators/object';

@tagName('')
export default class FormSelect extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  open: boolean = false;

  options: [] = [];

  @action
  clickSelect() {
    this.toggleProperty('open')
  }

  @action
  setFilter(option: string) {
    if (option == 'Alle') {
      this.set('activeFilter', '');
    } else {
      this.set('activeFilter', option);
    }
    
    this.set('open', false);
  }
};
