import Component from '@ember/component';
import { tagName, classNames, attribute } from '@ember-decorators/component';
import { computed, action } from '@ember-decorators/object';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember-decorators/service';


@tagName('div')
@classNames('ral-color', 'pull-left')
export default class AppCabincolorsColor extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  visible: boolean = false;

  @service() amplify: any;

  // @ts-ignore doenst know definite assignment "!"
  onClick: (item: any) => void;

  @attribute
  @computed('item')
  get style() {
    const hex = this.get('item.rgb_hex');
    return htmlSafe(`background: ${hex};     border: 1px solid rgba(29, 37, 59, 0.2);`);
  }

  @action
  click() {
    if (this.get('model.material') == "Edelstahl") {
      return;
    }

    // $("#RALWahlText").html("RAL " + this.item.code + " : " + this.item.name);
    // $("#RALWahl").css({ "background-image" : "none" , "background-color" : this.item.rgb_hex });
    this.onClick(this.item);
    this.amplify.trigger('3DView:setColor', this.item);
  }

  @action
  mouseEnter() {
    this.set('visible', true);
  }
  @action
  mouseLeave() {
    this.set('visible', false);
  }
};
