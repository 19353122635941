import Component from "@ember/component";
import { className } from "@ember-decorators/component";
import { action, computed } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import OrderService from "fimab-konfigurator/order/service";

@className("app-configurator-bar")
export default class AppConfiguratorBar extends Component.extend({
  queryParams: ['step'],
  step: null
  // anything which *must* be merged to prototype here
}) {
  @service("order") orderService: OrderService;
  @service() calculation;
  @service() amplify;
  @service() notify;
  @service router: RouterService;

  isPopupVisible = false;

  @computed("currentOrder.moduleDefinition.Breite")
  get breiteIsValid() {
    if (this.amplify.currentUser !== null) {
      if (this.amplify.currentUser.role === "Admin") {
        return true;
      }
    }

    return (
      this.get("currentOrder.moduleDefinition.Breite") >=
        this.get("currentOrder.cabinet.width.min") &&
      this.get("currentOrder.moduleDefinition.Breite") <
        this.get("currentOrder.cabinet.width.max") + 1
    );
  }
  @computed("currentOrder.moduleDefinition.Hoehe")
  get hoeheIsValid() {
    if (this.amplify.currentUser !== null) {
      if (this.amplify.currentUser.role === "Admin") {
        return true;
      }
    }
    return (
      this.get("currentOrder.moduleDefinition.Hoehe") >=
        this.get("currentOrder.cabinet.height.min") &&
      this.get("currentOrder.moduleDefinition.Hoehe") <
        this.get("currentOrder.cabinet.height.max") + 1
    );
  }
  @computed("currentOrder.moduleDefinition.Laenge")
  get laengeIsValid() {
    if (this.amplify.currentUser !== null) {
      if (this.amplify.currentUser.role === "Admin") {
        return true;
      }
    }
    return (
      this.get("currentOrder.moduleDefinition.Laenge") >=
        this.get("currentOrder.cabinet.laenge.min") &&
      this.get("currentOrder.moduleDefinition.Laenge") <
        this.get("currentOrder.cabinet.laenge.max") + 1
    );
  }

  @computed("currentOrder.name")
  get NameIsValid() {
    return this.get("currentOrder.name.length") >= 1;
  }

  @action
  showColorPickPopup() {
    if(!this.disabledFlag && this.disabledFlagStep4) {
      this.set("isPopupVisible", true);
    }
  }

  @action
  async setDefaultColor() {
    this.set("currentOrder.finish", "Grobstruktur glänzend");
    this.set("currentOrder.color", {
      code: "7035",
      name: "Light grey",
      rgb_approx: "215-215-215",
      rgb_hex: "#D7D7D7"
    });
    this.closePopup();
    await this.router.transitionTo('konfigurator', this.get("currentOrder.share"), { queryParams: { step: "4" }});
  }

  @action
  closePopup() {
    this.set("isPopupVisible", false);
  }

  @computed("currentOrder.color", "currentOrder.material")
  get colorIsValid() {
    console.log("color", this.get("currentOrder"));
    
    return this.get("currentOrder.color.code") != "keine" || this.get("currentOrder.material") == "Edelstahl";
  }

  @className("")
  @computed("breiteIsValid", "hoeheIsValid", "laengeIsValid", "NameIsValid")
  get isNew() {
    if (
      !this.get("breiteIsValid") ||
      !this.get("hoeheIsValid") ||
      !this.get("NameIsValid") ||
      !this.get("laengeIsValid")
    ) {
      return "isNew";
    } else {
      "isOld";
    }
  }

  @computed("breiteIsValid", "hoeheIsValid", "laengeIsValid", "NameIsValid")
  get disabledFlag() {
    if (
      !this.get("breiteIsValid") ||
      !this.get("hoeheIsValid") ||
      !this.get("NameIsValid") ||
      !this.get("laengeIsValid")
    ) {
      return true;
    } else {
      return false;
    }
  }

  @computed("breiteIsValid", "hoeheIsValid", "laengeIsValid", "NameIsValid", "colorIsValid")
  get disabledFlagStep4() {
    if (
      !this.get("breiteIsValid") ||
      !this.get("hoeheIsValid") ||
      !this.get("NameIsValid") ||
      !this.get("laengeIsValid") ||
      !this.get("colorIsValid")
    ) {
      return true;
    } else {
      return false;
    }
  }
  @computed(
    "currentOrder.moduleDefinition.Breite",
    "currentOrder.moduleDefinition.Hoehe",
    "currentOrder.moduleDefinition.Laenge",
    "currentOrder.moduleDefinition.ausschnitte",
    "currentOrder.material",
    "currentOrder.finish",
    "currentOrder.count",
    "currentOrder.color.name"
  )
  get newPrice() {
    this.set("orderService.order", this.currentOrder);
    return this.get("orderService").newPrice;
  }
  @computed("price")
  get price() {
    return this.get("orderService").price;
  }

  @action
  nextStep() {
    this.next(...arguments);
  }
}
