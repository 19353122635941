import Route from "@ember/routing/route";
import { inject as service } from "@ember-decorators/service";
import CREATE_ORDER from "fimab-konfigurator/gql/order/createOrder";
import uuid from "uuid/v4";
import Order from "fimab-konfigurator/model/order";
import { isEmpty } from "@ember/utils";

export default class OrderconfirmationRoute extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify;
  @service("order") orderService;
  @service("graphql") apolloService: any;
  @service() calculation: any;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  async model(params) {
    let order;
    let resp = await this.orderService.getLatestOrderByShare(params);

    if (resp == false) {
      alert(
        "Diese Konfiguration ist uns nicht bekannt oder es fehlt die Berechtigung diese einzusehen."
      );
    } else {
      order = new Order(resp);
    }

    if (!order.moduleDefinition.alternateShipping) {
      order.moduleDefinition.alternateShipping = {};
    }
    if (!order.moduleDefinition.alternatePayment) {
      order.moduleDefinition.alternatePayment = {};
    }

    let price = await this.calculation.getCalculatedPrice(order);
    price.price = price.cost * order.count;
    price.netprice = (price.price * 100) / 119;
    price.tax = price.price - price.netprice;
    let pdf = await this.get("amplify.Storage").get(
      `angebote/${order.share}/angebot_${order.ordernumber}.pdf`,
      {
        level: "public",
      }
    );

    return {
      order,
      pdf,
      price,
    };
  }
}
