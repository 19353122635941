import Component from '@ember/component';
import { action } from '@ember-decorators/object';
import { tagName } from '@ember-decorators/component';
import apolloMixin from 'fimab-konfigurator/mixins/apollo'

@tagName('')
export default class AppColorconfig extends Component.extend(apolloMixin) {
  showPanel: Boolean = false;

  @action
  togglePanel() {
    this.toggleProperty('showPanel');
  }
};
