define("fimab-konfigurator/components/spin-kit", ["exports", "ember-spin-kit/components/spin-kit"], function (_exports, _spinKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _spinKit.default;
    }
  });
});
