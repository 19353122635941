import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import { task } from "ember-concurrency-decorators";
import {
  validatePresence,
  validateLength,
  validateConfirmation,
  validateFormat,
} from "ember-changeset-validations/validators";
import lookupValidator from "ember-changeset-validations";
import gql from "graphql-tag";
import Changeset from "ember-changeset";
const mutation = gql`
  mutation(
    $create: UserCreateInput!
    $update: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    upsertUser(create: $create, update: $update, where: $where) {
      id
      email
    }
  }
`;
const LAST_CUSTOMERID = gql`
  query {
    users(orderBy: customerId_DESC, first: 1) {
      id
      customerId
    }
  }
`;
const query = gql`
  query($where: UserWhereInput) {
    users(where: $where, first: 1) {
      id
      email
      firstname
      company
      customerId
      salutation
      lastname
      city
      address
      zip
      department
      country
      taxId,
      phone
    }
  }
`;

export default class AccountRegister extends Component.extend({}) {
  @service amplify: any;
  @service router: any;
  @service("graphql") apolloService: any;

  userSub: string = "";

  userValidations = {
    email: validateFormat({
      type: "email",
    }),
    firstname: [validatePresence(true)],
    lastname: [validatePresence(true)],
    password: [
      validateLength({
        min: 8,
      }),
    ],
    address: [validatePresence(true)],
    zip: [validatePresence(true)],
    salutation: [validatePresence(true)],
    city: [validatePresence(true)],
    phone: [validatePresence(true)],
  };

  newUser = {
    email: "",
    password: "",
    passwordConfirmation: "",
    title: "Herr",
    salutation: "Herr",
    firstname: "",
    lastname: "",
    company: "",
    city: "",
    country: "",
    zip: "",
    address: "",
    privacy: false,
    taxId: "",
    department: "keine",
    phone: ""
  };

  departments: string[] = [
    "keine",
    "Einkauf",
    "Vertrieb",
    "Konstruktion",
    "Planung",
    "Geschäftsführung",
    "Sonstige",
  ];

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
    let model = this.get("newUser");
    this.changeset = new Changeset(
      model,
      lookupValidator(this.userValidations),
      this.userValidations
    );
  }

  @task({
    maxConcurrency: 1,
  })
  *loadMatchingAccounts() {
    let match: string = this.changeset
      .get("email")
      .split("@")[1]
      .toLocaleLowerCase();
    if (
      [
        "gmail.com",
        "googlemail.com",
        "gmx.net",
        "web.de",
        "t-online.de",
        "1und1.de",
        "yahoo.com",
      ].includes(match)
    ) {
      this.changeset.set("address", "");
      this.changeset.set("city", "");
      this.changeset.set("zip", "");
      this.changeset.set("company", "");
      this.changeset.set("customerId", 0);
      this.changeset.set("taxId", "");
      this.changeset.set("phone", "");
      this.set("step", 2);
      return;
    }
    this.get("apollo")
      .query(
        { query, variables: { where: { email_contains: match } } },
        "users"
      )
      .then(async (data) => {
        if (data.length > 0) {
          let m = data[0];
          this.changeset.set("address", m.address);
          this.changeset.set("city", m.city);
          this.changeset.set("zip", m.zip);
          this.changeset.set("company", m.company);
          this.changeset.set("customerId", m.customerId);
          this.changeset.set("taxId", m.taxId);
        } else {
          this.changeset.set("address", "");
          this.changeset.set("city", "");
          this.changeset.set("zip", "");
          this.changeset.set("company", "");

          const LAST_CUSTOMERID = gql`
            query {
              users(orderBy: customerId_DESC, first: 1) {
                id
                customerId
              }
            }
          `;

          let lastUsers = await this.get("apollo").query(
            { query: LAST_CUSTOMERID },
            "users"
          );

          this.changeset.set(
            "customerId",
            lastUsers.firstObject.customerId + 1
          );
          this.changeset.set("taxId", "");
        }
        this.set("step", 2);
      });
  }

  @action
  next(step: number) {
    this.set("step", step);
  }
  @action
  submitForm(newUser: object) {
    this.set("step", 2);
    this.registerNewUser.perform(newUser);
  }
  @action
  focusElement() {
    //this.$(event.target).parent('.input-group').addClass("input-group-focus")
  }
  @action
  unFocusElement(event: object) {
    //this.$(event.target).parent('.input-group').removeClass("input-group-focus")
  }

  @task({
    maxConcurrency: 1,
  })
  *registerNewUser(newUser: object) {
    const email = newUser.get("email").toLowerCase(),
      password = newUser.get("password");
    return this.amplify.Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        email: email.toLowerCase(),
      },
    })
      .then(async (data) => {
        try {
          this.set("userSub", data.userSub);
          this.set("step", 3);
          this.changeset.set("cognitoUser", this.userSub);

          this.changeset.save();
          let lastCustomer = await this.apollo.query(
            { query: LAST_CUSTOMERID },
            "users"
          );
          let variables = {
            create: {
              ...this.get("newUser"),
              payment: "Vorkasse",
              customerId: lastCustomer[0].customerId + 1,
            },
            update: this.get("newUser"),
            where: {
              email: this.get("newUser.email").toLowerCase(),
            },
          };

          delete variables.create.passwordConfirmation;
          delete variables.update.passwordConfirmation;
          delete variables.create.password;
          delete variables.update.password;
          delete variables.update.customerId;

          let user = await this.apollo.mutate(
            { mutation, variables },
            "upsertUser"
          );
          fbq("track", "CompleteRegistration");
        } catch (e) {
          console.error(e);
        }
      })
      .catch((err) => {
        this.onError(err);
      });
  }
  @task({
    maxConcurrency: 1,
  })
  *activateAccount() {}
}
