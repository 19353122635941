import Application from "@ember/application";
import Resolver from "./resolver";
import loadInitializers from "ember-load-initializers";
import config from "./config/environment";
import Ember from "ember";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

// Sentry.init({
//   dsn: "https://cab2b86342294d2095791a8cb5607342@o99865.ingest.sentry.io/1889749",
//   tracesSampleRate: 1.0,
//   environment: config.sentry.environment,
//   debug: config.sentry.debug,
//   integrations: [new Integrations.Ember()],
//   attachStacktrace: true,
//   release: `v${config.APP.version.split("+")[0]}`,
// });

const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver,
});

loadInitializers(App, config.modulePrefix);

Ember.deprecate = function () {};
Ember.warn = function () {};
export default App;
