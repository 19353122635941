import Controller from "@ember/controller";
import { action } from "@ember-decorators/object";
import gql from "graphql-tag";
import { inject as service } from "@ember-decorators/service";
import { getOwner } from "@ember/application";

const mutation = gql`
  mutation($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
    updateOrder(data: $data, where: $where) {
      id
      deleted
    }
  }
`;

export default class Konfiguratoroverview extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService: any;
  @service("router") router: any;

  @service() notify;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  @action
  async deleteOrder(order: any) {
    const variables = {
      where: {
        id: order.id,
      },
      data: {
        deleted: true,
      },
    };

    let deletedOrder = await this.get("apollo").mutate(
      { mutation, variables, refetchQueries: ["findManyOrders"] },
      "updateOrder"
    );
    let route = getOwner(this).lookup(`route:konfiguratoroverview`);

    this.get("notify").success("Bestellung wurde gelöscht!");
    await route.refresh();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    konfiguratoroverview: Konfiguratoroverview;
  }
}
