import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import fetch from "fetch";
import { inject as service } from "@ember-decorators/service";
import Amplify from "aws-amplify";
import { isEmpty } from "@ember/utils";
import { run } from "@ember/runloop";
import { task } from "ember-concurrency";
import moment from "moment";

export default class AppCalculation extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify;
  @service() router;
  @service("order") orderService;
  @service() order: any;
  @service() notify: any;

  count: number = 1;
  createOrderConfirmationDone = false;
  redirect: string = "";

  successMessageAngebot: boolean = false;
  isCreatingConfirmation: boolean = false;
  isLoading: boolean = false;
  isLoading2: boolean = false;

  errors: object = {};

  successMessage: boolean = false;
  agb: boolean = false;
  widerruf: boolean = false;

  deliverydate?: string;
  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;

  confirmationBody: string = "";
  confirmationBodyEnd: string = "";

  thumbs: object[] = [
    {
      src: window.location.origin + "/schrank2.jpg",
      w: 800,
      h: 600,
      title: "Thumb1",
    },
    {
      src: window.location.origin + "/schrank2.jpg",
      w: 800,
      h: 600,
      title: "Thumb1",
    },
    {
      src: window.location.origin + "/schrank2.jpg",
      w: 800,
      h: 600,
      title: "Thumb1",
    },
    {
      src: window.location.origin + "/schrank2.jpg",
      w: 800,
      h: 600,
      title: "Thumb1",
    },
  ];

  constructor() {
    super(...arguments);
    let d = Date.now();
    this.set("timestamp", d);
    this.amplify.trigger("3DView:screenshot");
    this.order.on("order:saved", this, () => {
      this.rerender();
    });
  }
  willDestroy() {
    this.order.off("order:saved");
  }

  async didReceiveAttrs() {
    // Lieferdatum errechnen ----------------
    this.weekday = parseInt(moment().format("d"));
    this.hour = parseInt(moment().format("k"));
    if (this.hour >= 16) {
      if (this.weekday === 6) {
        this.weekday = 0;
      } else {
        this.weekday++;
      }
      this.morgen = " morgen ";
      this.dayplus = 1;
    }

    if (this.weekday === 0) {
      this.daystoadd = 11 + this.dayplus; // +7 Tage weil 3 Wochenende!
    } else if (this.weekday === 6) {
      this.daystoadd = 12 + this.dayplus;
    } else {
      this.daystoadd = 13 + this.dayplus;
    }

    let feiertage = [
      "15.04.2022",
      "18.04.2022",
      "01.05.2022",
      "26.05.2022",
      "06.06.2022",
      "16.06.2022",
      "25.07.2022",
      "26.07.2022",
      "27.07.2022",
      "28.07.2022",
      "29.07.2022",
      "30.07.2022",
      "31.07.2022",
      "01.08.2022",
      "02.08.2022",
      "03.08.2022",
      "04.08.2022",
      "05.08.2022",
      "06.08.2022",
      "08.08.2022",
      "15.08.2022",
      "20.09.2022",
      "03.10.2022",
      "31.10.2022",
      "01.11.2022",
      "16.11.2022",
      "25.12.2022",
      "26.12.2022",
    ];
    let delay: number = 0;

    let mom = moment();
    let u = 0;

    while (u <= this.daystoadd) {
      mom.add(1, "days");

      if (feiertage.indexOf(mom.format("DD.MM.Y")) < 1) {
        u++;
      }
    }

    if (parseInt(mom.format("d")) === 5) {
      mom.add(3, "days");
    } else if (parseInt(mom.format("d")) === 6) {
      mom.add(2, "days");
    } else if (parseInt(mom.format("d")) === 0) {
      mom.add(1, "days");
    }

    this.set("deliverydate", mom.format("DD.MM.Y"));
    this.set("model.moduleDefinition.deliverydate", this.deliverydate);

    // Lieferdatum errechnen ----------------
  }

  validate() {
    if (!this.agb) {
      this.set("errors.agb", "Sie müssen der AGB zustimmen");
    }
    if (!this.widerruf) {
      this.set(
        "errors.widerruf",
        "Sie müssen den Widerrufshinweisen zustimmen"
      );
    }
    if (isEmpty(this.get("model.name"))) {
      this.set("errors.name", "Bitte vergeben Sie eine interne Bezeichnung");
    }
    if (isEmpty(this.get("model.count")) || this.get("model.count") < 1) {
      this.set("errors.count", "Bitte geben Sie eine Stückzahl an");
    }
    if (Object.keys(this.get("errors")).length == 0) {
      return true;
    } else {
      return false;
    }
  }

  @action
  async createOrder() {
    this.set("isOrdering", true);
    this.set("errors", {});

    if (this.validate()) {
      this.set("isOrdering", false);

      this.onSaveOrder();
    } else {
      run.later(() => {
        this.set("isOrdering", false);
      }, 500);
    }
  }

  confirmationEmail = null;
  confirmationTitle = "";

  @action
  async createEmailTemplate() {
    if (this.model.ordernumber == null) {
      await this.angebot(false);
    }

    let pdf = await this.get("amplify.Storage").get(
      `angebote/${this.model.share}/angebot_${this.model.ordernumber}.pdf`,
      {
        level: "public",
        expires: 604800,
      }
    );

    if (this.model.user === null) {
      await this.orderService.saveOrder.perform(this.model);

      this.set("model", this.orderService.get("order"));
    }

    var new_url = pdf.substring(0, pdf.indexOf("?"));

    this.set(
      "confirmationBody",
      `
Guten Tag,
anbei erhalten Sie die Bestellanforderung von ${this.model.user.firstname} ${this.model.user.lastname}, mit der Bitte die Bestellung auszuführen.`
    );
    this.set(
      "confirmationBodyEnd",
      `
    Folgende Bestellanforderung wurde erstellt:
    Die Bestellung können Sie unter folgendem Link ausführen.

    <a target="_blank" href="${window.location.href.replace(
      "konfigurator",
      "orderconfirmation"
    )}"><b style="text-decoration:underline;">Online-Bestellformular</b></a>

    Das Angebot im Detail als PDF finden Sie hier.

    <a  target="_blank" href="${
      pdf.split("?")[0]
    }"><b style="text-decoration:underline;">Angebot Download</b></a>

    --
    Die E-Mail und das Angebot wurde mit dem Online Schaltschrank Konfigurator ARMARiO erstellt.
    Es freut uns sehr, dass wir auch Sie für unsere kundenspezifisch individuellen Schaltschranklösungen begeistern können.
    Auf unserer Internetseite www.armario.eu können sie selbst 24/7 mit wenigen Klicks weitere Modelle auswählen, Lochbilder gestalten, Farben auswählen, Preise einsehen und Bestellungen aufgeben. Egal ob Montag morgens, oder Samstag abends.
    Ein Erklärvideo zum weltweit einzigen Konfigurator für Schaltschränke sehen Sie hier:
    https://youtu.be/GQyqxLqHtIo
    Hier finden Sie 2 Videos zu ganz außergewöhnlichen Schaltschrankkonstruktionen:
    https://youtu.be/qNUgZijjQlE - https://youtu.be/vjcyvMdtvCo.
    Für Rückfragen stehen wir Ihnen jederzeit gerne zur Verfügung.
    FiMAB GmbH & Co. KG
    Bühlstrasse 8
    75387 Neubulach
    Telefon +49 (7053) 96839-0
    Telefax +49 (7053) 96839-19
    E-Mail: info@fimab.eu
    http://www.fimab.eu
    Geschäftsführer: Markus Fiedler
    Registergericht Amtsgericht Stuttgart, HRB 330801
      `
    );
    this.set(
      "confirmationBodyEndFormatted",
      `Zum Projekt ${this.get(
        "model.name"
      )} wurde folgende Bestellanforderung an Sie gestellt:
Die Bestellung können Sie unter folgendem Link ausführen.

<a target="_blank" href="${window.location.href.replace(
        "konfigurator",
        "orderconfirmation"
      )}"><b style="text-decoration:underline;">Bestellungsübersicht</b></a>

Das Angebot im Detail als PDF finden Sie hier.

<a target="_blank" href="${pdf}"><b style="text-decoration:underline;">Angebot Download</b></a>`.replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + "<br>" + "$2"
      )
    );
  }
  sendingOrderToBuyer = false;
  @action
  async createOrderConfirmation() {
    this.set("sendingOrderToBuyer", true);
    this.set(
      "confirmationTitle",
      `[ARMARiO] - Angebot: ${this.model.ordernumber} / Neue Bestellanforderung von ${this.model.user.firstname} ${this.model.user.lastname} wartet auf Ihre Freigabe`
    );
    try {
      const emailNotificationCustomer2 = await fetch(
        "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.confirmationEmail,
            subject: this.confirmationTitle,
            content:
              this.get("confirmationBody").replace(
                /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                "$1" + "<br>" + "$2"
              ) +
              this.get("confirmationBodyEnd").replace(
                /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                "$1" + "<br>" + "$2"
              ),
          }),
        }
      );
      const content2 = await emailNotificationCustomer2.text();
      this.set("model.moduleDefinition.orderBuyer", this.confirmationEmail);
      this.order.saveOrder.perform(this.get("model"));
      this.set("isCreatingConfirmation", false);
      this.set("sendingOrderToBuyer", false);
      this.set("successMessage", true);
      this.set("createOrderConfirmationDone", true);
    } catch (e) {
      console.error(e);
    }
  }

  screenmodal: boolean = false;

  @action
  openScreenModal(screen: object) {
    this.set("activeScreen", screen);
    this.set("screenmodal", true);
  }

  @action
  async copyOrder() {
    this.set("isLoading2", true);
    let newOrder = await this.orderService.createNewOrderFromExisting.perform(
      this.get("model")
    );
    this.set("isLoading2", false);
    return this.get("router").transitionTo("konfigurator", newOrder.share);
  }

  @action
  async angebot(download: boolean = true) {
    const date = new Date();
    this.set("isLoading", true);
    let ordernumber;

    if (isEmpty(this.get("model.ordernumber"))) {
      ordernumber = await this.order.getNextOrderNumber();

      this.set("model.ordernumber", ordernumber);
    } else {
      ordernumber = this.get("model.ordernumber");
    }
    if (isEmpty(this.get("model.user"))) {
      this.set("model.user", this.get("amplify.currentUser"));
    }

    this.order.saveOrder.perform(this.get("model"));
    this.order.setOrderStatus.perform(this.get("model"), "NEU");
    this.set("model.status", "NEU");
    const company = this.get("model.user.company");
    const fullname = this.get("model.user.fullname");
    const street = this.get("model.user.address");
    const city = this.get("model.user.city");
    const zip = this.get("model.user.zip");
    const orderName = this.get("model.name");
    const articleNumber = ordernumber;
    const internalordernumber = this.get("model.internalordernumber");
    const angebotsnummer = ordernumber;
    const count = parseInt(this.get("model.count"));
    const model = this.get("model.cabinet.name");
    const breite = this.get("model.moduleDefinition.Breite");
    const hoehe = this.get("model.moduleDefinition.Hoehe");
    const laenge = this.get("model.moduleDefinition.Laenge");
    const ral = this.get("model.color.code");
    const material = this.get("model.material");
    const finish = this.get("model.finish");
    let Baugruppenteile = [];

    if (!isEmpty(this.get("model.moduleDefinition.sides.VORNE.components"))) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.VORNE.components").map(
          (Bauteil) => {
            Bauteil.type = "VORNE";
            return Bauteil;
          }
        )
      );
    }
    if (!isEmpty(this.get("model.moduleDefinition.sides.HINTEN.components"))) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.HINTEN.components").map(
          (Bauteil) => {
            Bauteil.type = "HINTEN";
            return Bauteil;
          }
        )
      );
    }
    if (!isEmpty(this.get("model.moduleDefinition.sides.LINKS.components"))) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.LINKS.components").map(
          (Bauteil) => {
            Bauteil.type = "LINKS";
            return Bauteil;
          }
        )
      );
    }
    if (!isEmpty(this.get("model.moduleDefinition.sides.RECHTS.components"))) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.RECHTS.components").map(
          (Bauteil) => {
            Bauteil.type = "RECHTS";
            return Bauteil;
          }
        )
      );
    }
    if (!isEmpty(this.get("model.moduleDefinition.sides.OBEN.components"))) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.OBEN.components").map(
          (Bauteil) => {
            Bauteil.type = "OBEN";
            return Bauteil;
          }
        )
      );
    }
    if (!isEmpty(this.get("model.moduleDefinition.sides.UNTEN.components"))) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.UNTEN.components").map(
          (Bauteil) => {
            Bauteil.type = "UNTEN";
            return Bauteil;
          }
        )
      );
    }
    if (
      !isEmpty(
        this.get("model.moduleDefinition.sides.MONTAGEPLATTE.components")
      )
    ) {
      Baugruppenteile.pushObjects(
        this.get("model.moduleDefinition.sides.MONTAGEPLATTE.components").map(
          (Bauteil) => {
            Bauteil.type = "MONTAGEPLATTE";
            return Bauteil;
          }
        )
      );
    }

    let logmodel = this.get("model");
    delete logmodel.screens;
    try {
      this.get("amplify.Storage").put(
        `${this.get("model.user.email")}/A_${this.get(
          "model.ordernumber"
        )}_${new Date().getTime()}.json`,
        JSON.stringify(logmodel),
        { level: "public" }
      );
    } catch (e) {}

    try {
      const price = Math.round(this.get("amplify.preis") * 100) / 100;

      const email = this.model.user.email;
      const emailNotification = await fetch(
        "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: "intern",
            subject: `Angebot: ${ordernumber} / Kundenbezeichnung: ${orderName}`,
            content: `Neues Angebot von ${email}<br>
          ${this.model.cabinet.name} - B ${breite}  H ${hoehe}  T ${laenge}
          <br>
          Summe: ${Math.round(price * 100) / 100}<br>
          <br>
          `,
          }),
        }
      );
      const content2 = await emailNotification.text();
    } catch (e) {
      console.log(e);
    }

    let modelnumber = "";
    let tempHoehe = parseInt(this.model.moduleDefinition.Hoehe);
    if (
      (this.model.moduleDefinition.Cabin == 1 ||
        this.model.moduleDefinition.Cabin == 2) &&
      tempHoehe > 670
    ) {
      modelnumber = "239035";
    }
    if (
      (this.model.moduleDefinition.Cabin == 1 ||
        this.model.moduleDefinition.Cabin == 2) &&
      tempHoehe <= 670
    ) {
      modelnumber = "239035";
    }
    if (this.model.moduleDefinition.Cabin == 2) {
      modelnumber = "252826";
    }
    if (this.model.moduleDefinition.Cabin == 3 && tempHoehe > 1100) {
      modelnumber = "252725";
    }
    if (
      this.model.moduleDefinition.Cabin == 3 &&
      tempHoehe > 670 &&
      tempHoehe <= 1100
    ) {
      modelnumber = "240954";
    }
    if (this.model.moduleDefinition.Cabin == 3 && tempHoehe <= 670) {
      modelnumber = "252825";
    }
    if (this.model.moduleDefinition.Cabin == 4) {
      modelnumber = "240652";
    }
    const rawResponse = await fetch("https://fimab-pdf.staging-netzreich.de/", {
      method: "POST",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          offerNo: `${angebotsnummer}`,
          date:
            date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear(),
          internalordernumber: internalordernumber,
          payment: isEmpty(this.get("model.user.payment"))
            ? "Zahlbar innerhalb 30 Tagen ohne Abzug"
            : this.get("model.user.payment"),
          shipping: isEmpty(this.get("model.user.shipping"))
            ? "-"
            : this.get("model.user.shipping"),
          customerNo: this.get("model.user.customerId"),
          supplierNo: "",
          customerAddress: `${company}<br>${fullname}<br>${street}<br>${zip} ${city}`,
          customerAddressee: `${fullname}`,
          order: [
            {
              orderName: `${orderName}`,
              bestellvermerk: `${this.model.moduleDefinition.bestellvermerk}`,
              itemNo: `${articleNumber}_${modelnumber}`,
              model: model,
              sizeConfig: `B:${breite}, H:${hoehe}, T:${laenge}, ${material}, Ral ${ral} - ${finish}`,
              description: Baugruppenteile.map((Bauteil) => {
                const name = isEmpty(Bauteil.name)
                  ? `Makro B: ${Bauteil.b}mm, L: ${Bauteil.h}mm, D: ${Bauteil.rounding}mm `
                  : Bauteil.name;
                return `Aussparung ${Bauteil.type} für '${name}' an x:${Bauteil.centerX}, y:${Bauteil.centerY}`;
              }),
              itemCount: count,
              priceEu: Math.round(this.get("amplify.preis") * 100) / 100,
            },
          ],
          customerUSt: this.get("model.user.taxId"),
        },
      ]),
    });
    const content = await rawResponse.blob();
    let exportFilename = "angebot.pdf";
    let pdf = await this.get("amplify.Storage")
      .put(
        `angebote/${this.model.share}/angebot_${angebotsnummer}.pdf`,
        content,
        {
          level: "public",
          contentType: "application/pdf",
        }
      )
      .then((result) => {
        return this.get("amplify.Storage").get(result.key, {
          level: "public",
        });
      })
      .catch((err) => console.log(err));

    fbq("track", "InitiateCheckout");

    if (!download) {
      this.set("isLoading", false);

      return;
    }

    //IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(content, exportFilename);
    } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(content);
      link.setAttribute("download", exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    this.set("isLoading", false);
  }
}
