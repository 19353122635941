import Component from '@ember/component';
import Changeset from 'ember-changeset';
import { action } from '@ember-decorators/object';
import { inject as service } from '@ember-decorators/service';
import mutation from 'fimab-konfigurator/gql/user/updateUser';
import removeFile from 'fimab-konfigurator/gql/file/deleteFile';

import { get } from '@ember/object';
import apolloMixin from 'fimab-konfigurator/mixins/apollo'
import { run } from '@ember/runloop';
export default class AccountProfile extends Component.extend(apolloMixin) {


  @service('history') history: History;

  file?: File;

  isLoading: any;

  didReceiveAttrs() {
    this.model = new Changeset(this.user);
  }


  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());

  }

  @action
  async fileUpload(data: any) {

    const file: File = data.target.files[0];
    await this.amplify.Storage.put(file.name, file, {level: 'protected'});

    let reader  = new FileReader();
    reader.addEventListener("load", ()=> {
      this.set('file', reader.result)
    }, false);
    reader.readAsDataURL(file);

    const where = {
      id: this.user.id
    }

    const variables = {
      avatar: {
        create: {
          name: file.name,
          type: "ACCOUNT_AVATAR",
          size: file.size,
          contentType: file.type,
          key: file.name
        }
      }
    }
    
    this.get('apollo').mutate({ mutation, variables: { data: variables, where: where } }, "user").then((data: any) => {
      this.amplify.Logger.info(data)
    });
  }

  @action
  removeFile(file) {
    this.amplify.Logger.info(`Trying to delete File ${file}`)
    const where = {
      id: file
    }

    this.get('apollo').mutate({ mutation: removeFile, variables: { where: where } }, "file").then((data: any) => {
      this.amplify.Logger.info(data)
    });
    this.file = null;
    this.set('user.avatar', null);

  }


  @action
  saveUser(data: any){

    const change = data.change;
    const id = get(data, 'id');
    const where = {
      id: id
    }

    this.set('isLoading', true);

    this.get('apollo').mutate({ mutation, variables: { data: change, where: where } }, "user").then((data: any) => {
      run.later(()=>{
        this.set('isLoading', false);
      }, 1000);
      //this.history.createHistoryEntry("USER", this.user.id, change, this.user.id)
    }).catch((err)=> {
      this.set('isLoading', false);
    });
    
  }
};
