import Component from '@ember/component';
import {
  computed,
  action
} from '@ember-decorators/object';
import fetch from 'fetch';
import {
  isEmpty
} from '@ember/utils';
import { inject as service } from '@ember-decorators/service';
import { next } from '@ember/runloop';
import { observes } from '@ember-decorators/object';

export default class AppCabincolors extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  @service() amplify;
  @service('order') orderService;

  itemHeight: number = 100;
  columns: [number] = [213];
  height: number = 0;

  edelstahl: boolean = false;
  stahlblech: boolean = true;
  finish1: boolean = false;
  finish2: boolean = true;

  activeItem = undefined;

  filter: string = '';

  didInsertElement() {
    this.set('height', this.$().find('.card.card-chart').height() - 20);
    console.log(this.get("model.color"));
    
    this.set("activeItem", this.get("model.color"))
  }

  @observes("model.color")
  observeModelColor() {
      this.set("activeItem", this.get("model.color"));
      console.log("OBSERVE", this.activeItem, this.get("model.color"));    
  }

  @computed('filter', 'colors')
  get filteredColors() {
    if (isEmpty(this.get('filter'))) {
      return this.get('colors');
    } else {
      return this.get('colors').then((colors)=>{
        return colors.filter((color) => {
          return color.code.includes(this.filter) || color.name.includes(this.filter);
        });
      })
    }
  }

  @computed()
  get colors() {
    return fetch('/colors.json').then((response) => {
      if (isEmpty(this.get('filter'))) {
        return response.json();
      } else {
        return response.json().filter((color) => {
          return color.includes(this.filter);
        });
      }
    });
  }
  didReceiveAttrs() {
    if (this.get('model.material') == 'Edelstahl') {
      this.set('edelstahl', true);
      this.set('stahlblech', false);
    } else {
      this.set('edelstahl', false);
      this.set('stahlblech', true);
    }
    if (this.get('model.finish') == 'Glatt glänzend') {
      this.set('finish1', true);
      this.set('finish2', false);
    } else {
      this.set('finish1', false);
      this.set('finish2', true);
    }
  }
  @action
  switchMaterial() {
    this.toggleProperty('edelstahl');
    this.toggleProperty('stahlblech');
    if (this.edelstahl) {
      this.set('model.material', 'Edelstahl');
      this.set('model.color', {
        code: "keine",
        name: "Light grey",
        rgb_approx: "215-215-215",
        rgb_hex: "#D7D7D7",
      });
      this.set('finish1', false);
      this.set('finish2', false);
      this.set('model.finish', '')
      this.removeColor();
    }
    if (this.stahlblech) {
      this.set('model.material', 'Stahlblech');
    }
    this.orderService.cacheOrder.perform(this.get('model'))
  }
  @action
  switchFinish(finish: string) {
    if (this.get('model.finish') === finish) {
      this.set('model.finish', '');
      this.set('model.color', {
        code: "keine",
        name: "Light grey",
        rgb_approx: "215-215-215",
        rgb_hex: "#D7D7D7",
      });
    } else {
      this.set('model.finish', finish);
      if (this.get('model.color.code') == 'keine') {
        this.set('model.color', {
          code: "9022",
          name: "Light grey",
          rgb_approx: "215-215-215",
          rgb_hex: "#D7D7D7",
        });
      }
    }
    this.orderService.cacheOrder.perform(this.get('model'))
  }

  @action
  searchRal(color: string) {
   
  }

  @action
  onColorChange(item:any) {
    this.set("activeItem", item);
  }

  @action
  removeColor() {
    console.log("REMOVECOLOR");
    
    this.amplify.trigger('3DView:removeColor');
    // $("#RALWahlText").html("Keine");
    // $("#RALWahl").css({ "background-image" : "url('/images/icons/ral_keine.jpg')" , "background-color" : "transparent" });
    this.set("activeItem", undefined);    
  }

  @action
  handlePageBottom() {
  
  }
};
