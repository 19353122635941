import Controller from "@ember/controller";
import { action } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import gql from "graphql-tag";
import jQuery from "jquery";
import { getOwner } from "@ember/application";
import moment from "moment";
import Component from "@ember/component";

const mutation = gql`
  mutation ($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
    updateOrder(data: $data, where: $where) {
      id
      deleted
    }
  }
`;

export default class Index extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService: any;

  modal?: boolean = false;
  showLogin?: boolean = false;
  @service amplify: any;
  mydata?: any;
  deliverydate?: string;
  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;
  @service isMobile: any;

  constructor() {
    super(...arguments);
    this.initWhatever();
    this.set("apollo", this.get("apolloService").createQueryManager());
    console.log(this.isMobile.any);

    // Lieferdatum errechnen ----------------
    this.weekday = parseInt(moment().format("d"));
    this.hour = parseInt(moment().format("k"));
    if (this.hour >= 16) {
      if (this.weekday === 6) {
        this.weekday = 0;
      } else {
        this.weekday++;
      }
      this.morgen = " morgen ";
      this.dayplus = 1;
    }

    if (this.weekday === 0) {
      this.daystoadd = 11 + this.dayplus; // +7 Tage weil 3 Wochenende!
    } else if (this.weekday === 6) {
      this.daystoadd = 12 + this.dayplus;
    } else {
      this.daystoadd = 13 + this.dayplus;
    }

    let feiertage = [
      "15.04.2022",
      "18.04.2022",
      "01.05.2022",
      "26.05.2022",
      "06.06.2022",
      "16.06.2022",
      "25.07.2022",
      "26.07.2022",
      "27.07.2022",
      "28.07.2022",
      "29.07.2022",
      "30.07.2022",
      "31.07.2022",
      "01.08.2022",
      "02.08.2022",
      "03.08.2022",
      "04.08.2022",
      "05.08.2022",
      "06.08.2022",
      "08.08.2022",
      "15.08.2022",
      "20.09.2022",
      "03.10.2022",
      "31.10.2022",
      "01.11.2022",
      "16.11.2022",
      "25.12.2022",
      "26.12.2022",
    ];
    let delay: number = 0;

    let mom = moment();
    let u = 0;

    while (u <= this.daystoadd) {
      mom.add(1, "days");

      if (feiertage.indexOf(mom.format("DD.MM.Y")) < 1) {
        u++;
        // console.log(mom.format("DD.MM.Y")+":"+u+"day:"+mom.format("d"));
      } else {
        // console.log(mom.format("DD.MM.Y")+":"+mom.format("d"));
      }
    }

    if (parseInt(mom.format("d")) === 5) {
      mom.add(3, "days");
    } else if (parseInt(mom.format("d")) === 6) {
      mom.add(2, "days");
    } else if (parseInt(mom.format("d")) === 0) {
      mom.add(1, "days");
    }

    this.deliverydate = mom.format("DD.MM.Y");
    // Lieferdatum errechnen ----------------
  }

  async initWhatever() {
    jQuery.ajax({
      url: "https://www.fimab.eu/armario-changelog",
      type: "GET",
      success: (data) => {
        this.set("mydata", jQuery(data).find("#page-content").html());
      },
    });
    jQuery(document).ready(function () {
      jQuery(".box-werbebanner").html("test");
    });
  }

  @action
  async deleteOrder(order: any) {
    const variables = {
      where: {
        id: order.id,
      },
      data: {
        deleted: true,
      },
    };

    let deletedOrder = await this.get("apollo").mutate(
      { mutation, variables, refetchQueries: ["findManyOrders"] },
      "updateOrder"
    );
    let route = getOwner(this).lookup(`route:konfiguratoroverview`);

    this.get("notify").success("Bestellung wurde gelöscht!");
    await route.refresh();
  }

  @action
  openModal() {
    this.toggleProperty("modal");
  }

  @action
  toogleStudie1() {
    jQuery("#fallstudie1").show();
    console.log(this.target);
  }
  @action
  toogleStudie2() {
    jQuery("#fallstudie2").show();
    console.log(this.target);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    index: Index;
  }
}
