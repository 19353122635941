import Component from '@ember/component';
import { action } from '@ember-decorators/object';

export default class AccountLoginChallengeLogin extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @action
  signIn() {
    this.onSignIn();
  }
  @action
  removeErrorMessage() {
    this.onRemoveErrorMessage();
  }
};
